import {
  ROMANEIO_PRODUCTS_LOAD,
  ROMANEIO_PRODUCTS_FETCH,
  ROMANEIO_PRODUCTS_SAVE,
  ROMANEIO_PRODUCTS_REMOVE
} from '../../constants';

import { ActionTypes, RomaneioProducts, RomaneioProductData } from '../../types';

type State = {
  loading: boolean;
  products: RomaneioProducts;
};

const defaultState: State = {
  loading: true,
  products: []
};

const reducerRomaneio = (state = defaultState, action: ActionTypes) => {
  return (
    {
      [ROMANEIO_PRODUCTS_LOAD]: {
        ...state,
        loading: action.payload,
      },
      [ROMANEIO_PRODUCTS_FETCH]: {
        ...state,
        loading: false,
        products: action.payload
      },
      [ROMANEIO_PRODUCTS_SAVE]: {
        ...state,
        loading: false,
       products: [
        ...state.products,
        action.payload
       ]
      },
      [ROMANEIO_PRODUCTS_REMOVE]:{
        ...state,
        loading: false,
        products: state.products.filter(product => product.id !== action.payload)
      },
    }[action.type] || state
  );
};

export default reducerRomaneio;
