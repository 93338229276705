import report from './reports';
import interesttax from './interesttax';
import products from './products';
import bag from './bag';
import navbar from './navbar';
import userconfig from './userconfig';
import roles from './roles';
import permitions from './permitions';
import romaneio from './romaneio';
import romaneioProducts from './romaneioProducts';

export default {
  ...report,
  ...interesttax,
  ...products,
  ...bag,
  ...navbar,
  ...userconfig,
  ...roles,
  ...permitions,
  ...romaneio,
  ...romaneioProducts
};
