import React, { createContext } from 'react';
import {
  Provider,
  createStoreHook,
  createDispatchHook,
  createSelectorHook,
  ReactReduxContextValue,
} from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from '../store/reduces';
import { State, ActionTypes } from '../types';

const middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

const composeEnhancers =
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

const StoreContext = createContext<ReactReduxContextValue<State>>(
  {} as ReactReduxContextValue<State>,
);

export const useStore = createStoreHook(StoreContext);
export const useDispatch = createDispatchHook(StoreContext);
export const useSelector = createSelectorHook(StoreContext);

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware)),
);

export const StoreProvider: React.FC = ({ children }) => {
  return (
    <Provider context={StoreContext} store={store}>
      {children}
    </Provider>
  );
};
