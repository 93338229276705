import { Dispatch } from 'redux';
import productsServiceProvider from '../../services/products.services';
import * as C from '../../constants';
import { Product } from '../../types';

type Request = {
  id?: number;
  search?: string;
  limit?: number;
  page?: number;
}

export const loadProduct = (active = true) => ({
  type: C.PRODUCTS_LOAD,
  payload: active,
});

export const fetchProducts = (products: Product[]) => ({
  type: C.PRODUCTS_FETCH,
  payload: products,
});

export const fetchPaginationProducts = (products: Product[]) => ({
  type: C.PRODUCTS_FETCH_PAGINATION,
  payload: products,
});

export const handleFetchProducts = ({id, search, limit, page}:Request) => async (dispatch: Dispatch) => {
  try { 
    dispatch(loadProduct());
    const products = await productsServiceProvider.all({id, search, limit, page});
    dispatch(fetchProducts(products));
  } catch (error) {
    dispatch(loadProduct(false));
    console.error(error);
  }
};

export const handleFetchPaginationProducts = ({id, search, limit, page}:Request) => async (dispatch: Dispatch) => {
  try { 
    dispatch(loadProduct());
    const products = await productsServiceProvider.all({id, search, limit, page});
    dispatch(fetchPaginationProducts(products));
  } catch (error) {
    dispatch(loadProduct(false));
    console.error(error);
  }
};

export default {
  loadProduct,
  fetchProducts,
  handleFetchProducts,
  handleFetchPaginationProducts
};
