import React, { useEffect, useRef, useState, useCallback } from "react";
import { format } from "date-fns";
import {
  Drawer,
  Button,
  Divider,
  Form,
  ControlLabel,
  FormGroup,
  FormProps,
  FormControl,
  DatePicker,
  Tooltip,
  Whisper,
  Notification,
} from "rsuite";
import { ReportState } from "../../../types";
import TextField from "../../Form/TextField";
import { stores_para_tocantins as spt } from "../../../utils";
import api from "../../../services/api.service";

import InputPickerStore from "../../Form/InputPickerStore";
import InputPickerSalespeople from "../../Form/InputPickerSalespeople";
import InputPickerFilter from "../../Form/InputPickerFilter";

type FormData = {
  LJ?: number;
  DI?: string;
  DF?: string;
  VD?: number;
  AR?: string;
  AV?: string;
  EN?: string;
  FILT?: number;
};

const DrawerNew: React.FC<{
  report: ReportState;
  show: boolean;
  handleClose: () => void;
}> = ({ report, show, handleClose }) => {
  const formRef = useRef<FormProps>(null);
  const [formData, setFormData] = useState<FormData>({
    LJ: 0,
  });
  const [pdf, setPDF] = useState(null);
  const [noFilter, setNoFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  let { fields, title } = report || {};
  fields = fields || [];

  useEffect(() => {
    if (report?.fields?.length === 0) {
      setNoFilter(true);
    }
  }, [report]);

  useEffect(() => {
    // Carregar relatório sem filtros
  }, [noFilter]);

  const handleChange = useCallback(
    (data) => {
      let dados = {}
      setFormData((old) => {
        if(data['DI']){
          dados = { ...dados, 'DI': format(new Date(data['DI']), "yyyy-MM-dd")}
        }
        if(data['DF']){
          dados = { ...dados, 'DF': format(new Date(data['DF']), "yyyy-MM-dd")}
        }
        // if (AR && AV && EN) {
        //   if (old?.LJ !== data?.LJ) {
        //     if (spt.includes(data?.LJ)) {
        //       return Object.assign(data, {
        //         AR: 1.0,
        //         AV: 0.5,
        //         EN: 0.5,
        //       });
        //     } else {
        //       return Object.assign(data, {
        //         AR: 1.5,
        //         AV: 1,
        //         EN: 1,
        //       });
        //     }
        //   }
        // }

        return dados;
      });
    },
    []
  );

  const handleSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      
      try {
        setLoading(true)
        const { data } = await api.post(
          `/pentaho/reports`,
          {
            file: report?.file_prpt,
            ...formData,
          },
          {
            responseType: "blob",
            headers: {
              Accept: "application/pdf",
            },
          }
        );
        const blob = new Blob([data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        setPDF(url);
        setLoading(false)
      } catch (e) {
        setLoading(false)
        Notification.warning({
          title: "Falha na visualização",
          description: "Ocorreu um erro ao abrir o relatório.",
        });
      }
    },
    [formData, report]
  );

  const tooltipPDFNewAba = <Tooltip>Abrir o PDF em outra aba.</Tooltip>;

  return (
    <Drawer full show={show} onHide={handleClose}>
      <Drawer.Header>
        <Drawer.Title>{title}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {!noFilter && (
          <>
            <Form
              layout="inline"
              onChange={handleChange}
              ref={formRef}
              formValue={formData}
            >
              {fields.includes('LJ') && <InputPickerStore />}
              {fields.includes('DI') && (
                <FormGroup>
                  <ControlLabel>
                    {fields.includes('DF') ? "Data Início" : "Data referência"}
                  </ControlLabel>
                  <FormControl name="DI" accepter={DatePicker} />
                </FormGroup>
              )}
              {fields.includes('DF') && (
                <FormGroup>
                  <ControlLabel>Data Fim</ControlLabel>
                  <FormControl name="DF" accepter={DatePicker} />
                </FormGroup>
              )}

              {fields.includes('VD') && <InputPickerSalespeople store={formData?.LJ || 0} />}
              {fields.includes('FILT') && <InputPickerFilter />}

              {fields.includes('AR') && (
                <TextField
                  name="AR"
                  label="Comissão a receber"
                  style={{ width: 100 }}
                />
              )}

              {fields.includes('AV') && (
                <TextField
                  name="AV"
                  label="Comissão a vista"
                  style={{ width: 100 }}
                />
              )}

              {fields.includes('EN') && (
                <TextField
                  name="EN"
                  label="Comissão entrata"
                  style={{ width: 100 }}
                />
              )}

              <Button onClick={handleSubmit} appearance="primary" disabled={loading}>
              {loading ? "Carregando relatório..." : "Carregar"}
              </Button>
            </Form>
            <Divider />
            {pdf && (
              <iframe src={pdf} style={{ width: "100%", height: "100%" }} />
            )}
          </>
        )}
      </Drawer.Body>
      <Drawer.Footer>
        <Whisper placement="top" trigger="hover" speaker={tooltipPDFNewAba}>
          <Button onClick={() => window.open(pdf)} appearance="primary">
            Abrir PDF
          </Button>
        </Whisper>
        <Button onClick={handleClose} appearance="subtle">
          Sair
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
};

export default DrawerNew;
