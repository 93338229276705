import { Dispatch } from 'redux';
import rolesServiceProvider from '../../services/roles.services';

import * as C from '../../constants';
import { Role } from '../../types';

export const loadRoles = (active = true) => ({
  type: C.ROLES_LOAD,
  payload: active,
});

export const receiveRoles = (roles: Role[]) => ({
  type: C.ROLES_FETCH,
  payload: roles,
});

export const handleReceiveRoles = () => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadRoles());
    const roles = await rolesServiceProvider.all();
    dispatch(receiveRoles(roles));
  } catch (error) {
    dispatch(loadRoles(false));
  }
};

export default {
  loadRoles,
  receiveRoles,
  handleReceiveRoles,
};
