import {
  BAG_LOAD,
  BAG_ADD,
  BAG_REMOVE,
  BAG_LIST,
  BAG_CLEAR,
  BAG_ADD_INPUT_VALUE,
  BAG_ADD_QTD_ITEM,
  BAG_ADD_PRICE_ITEM,
  BAG_ADD_DISCOUNT_ITEM,
  BAG_ADD_DISCOUNT_GERAL,
  BAG_ADD_PAYMENT_FORM,
} from '../../constants';

import { ActionTypes, ItemBag, Payment } from '../../types';
import { 
  reduceUpdateList, 
  reduceAddItem, 
  reduceChangeItem, 
  reduceChangePriceItem, 
  reduceChangeDiscountGeral 
} from '../../utils';

type State = {
  payment_form?: Payment;
  input_value?: number;
  discount?: number;  // calculado
  total_value?: number;  // calculado
  total_discount_value?: number;  // calculado
  total_final?: number;  // calculado
  loading: boolean;
  list: ItemBag[];
};

const defaultState: State = {
  loading: true,
  payment_form: null,
  input_value: 0,
  discount: 0,  
  total_value: 0,  
  total_discount_value: 0,  
  total_final: 0,
  list: [],
};

const reducerBag = (state = defaultState, action: ActionTypes) => {
  return (
    {
      [BAG_LOAD]: {
        ...state,
        loading: action.payload,
      },
      [BAG_LIST]: [...state.list],
      [BAG_ADD_INPUT_VALUE]:{
        ...state,
        loading: false,
        input_value: action.payload,
      },
      [BAG_ADD]: {
        ...state,
        loading: false,
        ...reduceAddItem(state.list, action.payload),
      },
      [BAG_ADD_QTD_ITEM]: {
        ...state,
        loading: false,
        ...reduceChangeItem(state.list, action.payload),
      },
      [BAG_ADD_PAYMENT_FORM]: {
        ...state,
        loading: false,
        payment_form: action.payload,
      },
      [BAG_ADD_DISCOUNT_ITEM]: {
        ...state,
        loading: false,
        ...reduceChangeItem(state.list, action.payload),
      },
      [BAG_ADD_DISCOUNT_GERAL]: {
        ...state,
        loading: false,
        ...reduceChangeDiscountGeral(state.list, action.payload),
      },
      [BAG_ADD_PRICE_ITEM]: {
        ...state,
        loading: false,
        ...reduceChangePriceItem(state.list, action.payload),
      },
      [BAG_REMOVE]: {
        ...state,
        loading: false,
        ...reduceUpdateList(state.list.filter(
          (itemBag: ItemBag) => itemBag.item?.codigo_produto !== action.payload?.codigo_produto
        ))
      },
      [BAG_CLEAR]: {
        ...state,
        loading: false,
        ...action.payload,
      },
    }[action.type] || state
  );
};

export default reducerBag;
