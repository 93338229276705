import React, { useEffect, useState, useCallback, useRef } from "react";
import { 
  Panel,
  Tooltip,
  Loader,
  Whisper,
  IconButton,
  TableCellProps,
  Icon,
  Form,
  Table,
  Modal,
  Divider,
  Button,
  Schema,
  FormProps,
  ControlLabel,
  FormControl,
  FormGroup
} from "rsuite";
import { useDebounce } from "use-debounce";
import {useHistory} from "react-router-dom"
import { useDispatch, useSelector } from "../hooks/store";
import Actions from "../store/actions";
import romaneiosServices from '../services/romaneios.services'
import InputPickerStore from '../components/Form/InputPickerStore'
import DateCell from '../components/Table/DateCell';
import { usePermition } from '../hooks/permitions';
import { Romaneio, State } from "../types";

const { Column, HeaderCell, Cell, Pagination:TablePagination } = Table;

const newRomaneioTooltip = <Tooltip>Criar um novo Romaneio [N]</Tooltip>;

const GoToCell = ({ rowData, handleGoToRomaneio, handleRemoteRomaneio, handlePDFGenerator, can, dataKey, ...props }:TableCellProps) => (
  <Cell {...props} style={{justifyContent: 'flex-start'}}>
    <IconButton 
      style={{cursor: "pointer", marginRight: 15}}
      onClick={() => handleGoToRomaneio(rowData)} 
      appearance="default" 
      icon={<Icon icon="angle-right" />} placement="right"
    >
      Ver
    </IconButton>
    <IconButton 
      style={{cursor: "pointer", marginRight: 15}}
      onClick={() => handlePDFGenerator(rowData)} 
      appearance="default" 
      color="orange" 
      icon={<Icon icon="print" />} placement="right"
    >
      Documento
    </IconButton>
    {can && (
      <IconButton 
      style={{cursor: "pointer", marginRight: 15}}
      onClick={() => handleRemoteRomaneio(rowData["id"])} 
      appearance="default" 
      color="red"
      icon={<Icon icon="trash2" />} placement="right"
    >
      Remover
    </IconButton>
    )}
    
  </Cell>
);

type Stores = {
  store_origin: number;
  store_destination: number;
}

const { NumberType } = Schema.Types;

const SchemaForm = Schema.Model<Stores>({
  store_origin: NumberType().isRequired("Informe a loja de origem"),
  store_destination: NumberType().isRequired("Informe a loja de destino"),
});

const PageRomaneio = () => {
  const formRef = useRef<FormProps>(null);
  const { loading, paginate } = useSelector((state: State) => state?.romaneios);
  const [filterRomaneioId, setFilterRomaneioId] = useState(0)
  const [filterOrigin, setFilterOrigin] = useState(0)
  const [filterDestination, setFilterDestination] = useState(0)
  const [formData, setFormData] = useState<Stores>({ store_origin: 6, store_destination: null });
  const [showModal, setShowModal] = useState(false)
  const [page, setPage] = useState(1)
  const [loader, setLoder] = useState(false)
  const [limit, setLimit] = useState(50)
  const {push, location} = useHistory();
  const dispatch = useDispatch();
  const { Can } = usePermition();
  const [romaneioId] = useDebounce(filterRomaneioId, 1000);

  const { data, total } = paginate;

  const eventKeyUp = (event) =>{
    event.preventDefault()

    if (['n', 'N'].includes(event.key)) { 
      if(Can(["ADMIN_FULL_ACCESS", "CREATE_ROMANEIO"])){
        toogleModal();
      }
    }
  }

  useEffect(() => {
    document.body.addEventListener('keyup', eventKeyUp)

    dispatch(Actions.handleReceveRomaneios({origin: filterOrigin, destination: filterDestination, limit, page}))

    return () => {
      document.body.removeEventListener("keyup", eventKeyUp);
    }
  },[]);

  useEffect(() => {
    dispatch(Actions.handleReceveRomaneios({origin: filterOrigin, destination: filterDestination, limit, page, romaneioId}))
  },[page, limit, filterOrigin, filterDestination, romaneioId]);

  const handleNewRomaneio = useCallback(async () => {
    
    if(formRef.current.check()){
      toogleModal()
      setLoder(true)
      // Criar um novo Romanei
      const romaneio = await romaneiosServices.save(formData);

      console.log(romaneio)
      // Navegar para novo romaneio
      push(`${location.pathname}/products`, romaneio)
    }
  }, [formData])

  const handleGoToRomaneio = useCallback((romaneio) => {
    push(`${location.pathname}/products`, romaneio)
  }, [])

  const handleRemoteRomaneio = useCallback((id) => {
    dispatch(Actions.handleRemoveRomaneio(id))
  }, [])

  const handleChangeOrigin= useCallback((value) => {
    setFilterOrigin(value)
  }, [filterOrigin])

  const handleChangeDestination = useCallback((value) => {
    setFilterDestination(value)
  }, [filterDestination])

  const handleChangeRomaneioId = useCallback((value) => {
    setFilterRomaneioId(value)
  }, [filterRomaneioId])

  const handleChangePage = (dataKey) => { setPage(dataKey) }

  const handleChangeLength = (dataKey) =>{ setLimit(dataKey) }

  const toogleModal = () => {
    setShowModal(old => !old)
  }

  const resetModal = () => {
    setShowModal(false)
    setFormData(null)
  }

  const handleChange = useCallback((data: Stores) => {
    setFormData(data);
  }, []);

  const renderHeader = () => (
      <div className="inline space-between" >
        <div style={{display: 'flex'}}>
        <h2>Romaneios </h2>
        <div style={{marginLeft: 20, display: "flex", alignItems: "center"}}>
          {filterOrigin ? <span><strong>Origem: </strong>{filterOrigin}</span> : null}
          {filterDestination ? <span style={{marginLeft: 15}}><strong>Destino: </strong>{filterDestination}</span> : null}
        </div>
        </div>
        
        <Form className="inline center">
            <FormGroup style={{width: 180, marginRight: 15}}>
              <ControlLabel style={{fontSize: 12}}>Buscar código</ControlLabel>
              <FormControl 
                style={{width: "100%"}} 
                name="romaneioId" 
                placeholder="código do romaneio" 
                onChange={handleChangeRomaneioId}
                autocomplete="off"
              />
            </FormGroup>
            <InputPickerStore 
              all 
              label="Filtrar origem" 
              onChange={handleChangeOrigin} 
              value={filterOrigin}
              style={{width: 180, marginRight: 15, fontSize: 12}}
            />
            <InputPickerStore 
              all 
              label="Filtrar destino" 
              onChange={handleChangeDestination}
              style={{width: 180, fontSize: 12}}
            />
            {Can(["ADMIN_FULL_ACCESS", "CREATE_ROMANEIO"]) && (
              <Whisper placement="top" trigger="hover" speaker={newRomaneioTooltip}>
              <IconButton 
                style={{cursor: "pointer", marginLeft: 20}}
                onClick={toogleModal} 
                abIndex={0} 
                appearance="primary" 
                color="green" 
                icon={<Icon icon="plus" />} placement="right"
              >
                Novo Ronameio
              </IconButton>
            </Whisper>
            )}
            
        </Form>
      </div>
  );

  const handlePDFGenerator = useCallback((romaneio: Romaneio) => {
    push(`/admin/romaneios/${romaneio.id}/print?origin=${romaneio.store_origin}&destination=${romaneio.store_destination}`)
  }, [])

  return (
    <Panel header={renderHeader()} id={'panelRomaneio'}>
        {loading && (
          <Loader backdrop center content="Carregando romaneios..."/>
        )}

        {loader && (
          <Loader backdrop center content="Criando novo romaneio..."/>
        )}

        <Table 
          fluid
          data={data} 
          style={{height: '100%', cursor: 'pointer'}}
          rowHeight={() => 55} 
          renderEmpty={() => (
            <div 
              className="rs-table-body-info" 
              style={{display: `${loading ? 'none': 'block'}`}}>
                Nenhum romaneio foi encontrato.
            </div>
          )}
        >
           <Column flexGrow={0.5}  align="left">
              <HeaderCell>Código</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column flexGrow={0.5} align="left">
              <HeaderCell>Loja de Origem</HeaderCell>
              <Cell dataKey="store_origin" />
            </Column>
            <Column flexGrow={0.5} align="left">
              <HeaderCell>Loja de Destino</HeaderCell>
              <Cell dataKey="store_destination" />
            </Column>
            <Column flexGrow={0.5} align="left">
              <HeaderCell>Criado</HeaderCell>
              <DateCell dataKey="created_at" />
            </Column>
            <Column flexGrow={2} align="left">
              <HeaderCell>Ação</HeaderCell>
              <GoToCell 
                dataKey="store_destination" 
                handleGoToRomaneio={handleGoToRomaneio} 
                handleRemoteRomaneio={handleRemoteRomaneio}
                handlePDFGenerator={handlePDFGenerator}
                can={Can(["ADMIN_FULL_ACCESS", "REMOVE_ROMANEIO"])}
              />
            </Column>
          </Table>
          <TablePagination
          lengthMenu={[
            {
              value: 20,
              label: 20
            },
            {
              value: 50,
              label: 50
            },
            {
              value: 100,
              label: 100
            }
          ]}
          activePage={page}
          displayLength={limit}
          total={total}
          onChangePage={handleChangePage}
          onChangeLength={handleChangeLength}
        />
        <Modal backdrop="static" show={showModal} onHide={toogleModal} size="sm">
          <Modal.Body>
            <p style={{textAlign: "center"}}>
              <Icon
                icon="remind"
                style={{
                  color: '#ffb300',
                  fontSize: 48
                }}
              />
            </p>
            <p style={{fontWeight: "bold", fontSize: 20, textAlign: "center"}}>Por favor informe a loja  de origem e destino.</p>
            <Divider />
            <Form
              fluid
              onChange={handleChange}
              model={SchemaForm}
              ref={formRef}
              formValue={formData}
              autocomplete="off"
              className="form-stores-romaneio"
            >
              <FormGroup>
                <InputPickerStore all label="Loja de Origem" name="store_origin"/>
              </FormGroup>
              <FormGroup>
                <InputPickerStore all label="Loja de Destino" name="store_destination"/>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleNewRomaneio} appearance="primary" disabled={loading}>
              Salvar
            </Button>
            <Button onClick={resetModal} appearance="subtle">
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
    </Panel>
  );
};

export default PageRomaneio;
