import { Dispatch } from 'redux';
import romaneioServiceProvider from '../../services/romaneios.services';

import * as C from '../../constants';
import { Romaneio, RomaneioParams, Paginate } from '../../types';

export const loadRomaneio = (payload = true) => ({
  type: C.ROMANEIO_LOAD,
  payload,
});

export const receiveRomaneio = (payload: Paginate<Romaneio>) => ({
  type: C.ROMANEIO_FETCH,
  payload,
});

export const setRomaneio = (payload: Romaneio) => ({
  type: C.ROMANEIO_SAVE,
  payload,
});

export const removeRomaneio = (payload: number) => ({
  type: C.ROMANEIO_REMOVE,
  payload,
});

type PropsReceive = {
    origin?: number;
    destination?: number;
    limit?: number;
    page?: number;
    romaneioId?: number;
  }

export const handleReceveRomaneios = ({ origin, destination, limit, page, romaneioId }: PropsReceive) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadRomaneio());
    const romaneios = await romaneioServiceProvider.index({origin, destination, limit, page, romaneioId});
    
    dispatch(receiveRomaneio(romaneios));
  } catch (error) {
    dispatch(loadRomaneio(false));
  }
};

export const handleSaveRomaneio = (params: RomaneioParams) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadRomaneio());
    const romaneio = await romaneioServiceProvider.save(params);
    
    dispatch(setRomaneio(romaneio));
  } catch (error) {
    dispatch(loadRomaneio(false));
  }
};

export const handleRemoveRomaneio = (id: number) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadRomaneio());
    await romaneioServiceProvider.remove(id);
    
    dispatch(removeRomaneio(id));
  } catch (error) {
    dispatch(loadRomaneio(false));
  }
};

export default {
  loadRomaneio,
  receiveRomaneio,
  setRomaneio,
  removeRomaneio,
  handleReceveRomaneios,
  handleSaveRomaneio,
  handleRemoveRomaneio
};
