import React, { MouseEvent, useCallback } from 'react';
import { FaPowerOff } from 'react-icons/fa';
import { useAuth } from '../../hooks/auth';

const ButtonSignOut: React.FC = () => {
  const { signOut } = useAuth();

  const handdleSignOut = useCallback((event: MouseEvent) => {
    event.preventDefault();
    signOut();
  }, []);

  return (
    <a href="#" className="btn-power-off" onClick={handdleSignOut}>
      <FaPowerOff size={14} />
      <span>Sair</span>
    </a>
  );
};

export default ButtonSignOut;
