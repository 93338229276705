// Actions types
export const REPORT_LOAD = 'REPORT_LOAD';
export const REPORT_FETCH = 'REPORT_FETCH';
export const REPORT_SEARCH = 'REPORT_SEARCH';
export const REPORT_LIST = 'REPORT_LIST';
export const REPORT_CREATE = 'REPORT_CREATE';
export const REPORT_UPDATE = 'REPORT_UPDATE';
export const REPORT_DELETE = 'REPORT_DELETE';

export const INTEREST_TAX_LOAD = 'INTEREST_TAX_LOAD';
export const INTEREST_TAX_FETCH = 'INTEREST_TAX_FETCH';
export const INTEREST_TAX_LIST = 'INTEREST_TAX_LIST';
export const INTEREST_TAX_CREATE = 'INTEREST_TAX_CREATE';
export const INTEREST_TAX_UPDATE = 'INTEREST_TAX_UPDATE';
export const INTEREST_TAX_DELETE = 'INTEREST_TAX_DELETE';

export const PRODUCTS_LOAD = 'PRODUCTS_LOAD';
export const PRODUCTS_FETCH = 'PRODUCTS_FETCH';
export const PRODUCTS_FETCH_PAGINATION = 'PRODUCTS_FETCH_PAGINATION';

export const BAG_LOAD = 'BAG_LOAD';
export const BAG_ADD = 'BAG_ADD';
export const BAG_REMOVE = 'BAG_REMOVE';
export const BAG_LIST = 'BAG_LIST';
export const BAG_CLEAR = 'BAG_CLEAR';


export const BAG_ADD_INPUT_VALUE = 'BAG_ADD_INPUT_VALUE';

export const BAG_ADD_DISCOUNT_ITEM = 'BAG_ADD_DISCOUNT_ITEM';
export const BAG_ADD_DISCOUNT_GERAL = 'BAG_ADD_DISCOUNT_GERAL';
export const BAG_ADD_QTD_ITEM = 'BAG_ADD_QTD_ITEM';
export const BAG_ADD_PRICE_ITEM = 'BAG_ADD_PRICE_ITEM';
export const BAG_ADD_PAYMENT_FORM = 'BAG_ADD_PAYMENT_FORM';

export const ROLES_LOAD = 'ROLES_LOAD';
export const ROLES_FETCH = 'ROLES_FETCH';

export const PERMITIONS_LOAD = 'PERMITIONS_LOAD';
export const PERMITIONS_FETCH = 'PERMITIONS_FETCH';
export const PERMITIONS_CREATE = 'PERMITIONS_CREATE';
export const PERMITIONS_UPDATE = 'PERMITIONS_UPDATE';
export const PERMITIONS_DELETE = 'PERMITIONS_DELETE';

export const BAG_TOTAL_DISCOUNT = 'BAG_TOTAL_DISCOUNT';
export const BAG_TOTAL_VALUE = 'BAG_TOTAL_VALUE';
export const BAG_TOTAL_DISCOUNT_VALUE = 'BAG_TOTAL_DISCOUNT_VALUE';
export const BAG_TOTAL_FINAL = 'BAG_TOTAL_FINAL';

export const NAVBAR = 'NAVBAR';
export const NAVBAR_TOGGLE = 'NAVBAR_TOGGLE';
export const NAVBAR_CLOSE = 'NAVBAR_CLOSE';
export const NAVBAR_SET = 'NAVBAR_SET';

export const USER_CONFIG = 'USER_CONFIG';
export const USER_CONFIG_LOAD = 'USER_CONFIG_LOAD';
export const USER_CONFIG_UPDATE = 'USER_CONFIG_UPDATE';
export const USER_CONFIG_SET = 'USER_CONFIG_SET';

export const ROMANEIO_LOAD = 'ROMANEIO_LOAD';
export const ROMANEIO_FETCH = 'ROMANEIO_FETCH';
export const ROMANEIO_SAVE = 'ROMANEIO_SAVE';
export const ROMANEIO_GET = 'ROMANEIO_GET';
export const ROMANEIO_REMOVE = 'ROMANEIO_REMOVE';

export const ROMANEIO_PRODUCTS_LOAD = 'ROMANEIO_PRODUCTS_LOAD';
export const ROMANEIO_PRODUCTS_FETCH = 'ROMANEIO_PRODUCTS_FETCH';
export const ROMANEIO_PRODUCTS_SAVE = 'ROMANEIO_PRODUCTS_SAVE';
export const ROMANEIO_PRODUCTS_REMOVE = 'ROMANEIO_PRODUCTS_REMOVE';