import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  FormControlProps,
  InputPicker,
} from 'rsuite';
import { InputPickerData } from '../../../types';
import { normalizeStores } from '../../../utils';
import api from '../../../services/api.service';

type Props = FormControlProps & {
  all?: boolean;
  label?: string;
}

const TextField = ({ all = false, label = 'Lojas', ...rest }: Props) => {
  const [stores, setStores] = useState<InputPickerData[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`${all ? '/stores' : '/stores/me'}`);
      const stores = normalizeStores(data || [] );
      if(all) {
        stores.shift();
      }

      setStores(stores);
    })();
  }, []);

  return (
    <FormGroup>
      <ControlLabel style={{fontSize: 14}}>{label}</ControlLabel>
      <FormControl name="LJ" {...rest} accepter={InputPicker} data={stores} />
    </FormGroup>
  );
};

export default TextField;
