import api from './api.service';
import { UserConfig } from '../types';

const update = async (userConfig: UserConfig): Promise<UserConfig> => {
  const { data } = await api.post<UserConfig>(`/userconfig`, userConfig);

  return data;
};

export default {
  update
};
