import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Drawer,
  Button,
  ControlLabel,
  FormGroup,
  List,
  FlexboxGrid,
  Grid,
  Icon,
  InputGroup,
  InputNumber,
  Whisper,
  Tooltip,
  IconButton
} from 'rsuite';
import { useDebounce } from "use-debounce";
import { ItemBag, State } from '../../../types';
import { useDispatch, useSelector } from "../../../hooks/store";
import Actions from "../../../store/actions";

import DrawerSimulate from '../DrawerSimulate'

const styleCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50px'
};

const styleFlexEnd = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  height: '50px'
};

const DrawerBag: React.FC<{
  show: boolean;
  handleClose: () => void;
  handleClearBag: () => void;
  isNewSimulate: boolean,
  handleIsNewSimulate: (is: boolean) => void,
}> = ({ show, handleClose, handleClearBag, isNewSimulate, handleIsNewSimulate }) => {
  const [showSimulate, setShowSimulate] = useState(false);
  const [discountGeral, setDiscountGeral] = useState(0);
  const { list, total_value, total_final  } = useSelector((state: State) => state?.bag);
  const dispatch = useDispatch();
  const [valueDiscountGeral] = useDebounce(discountGeral, 750);
  // const descounRef = useRef(null);

  const tooltipExcluir = <Tooltip>Remover item</Tooltip>;

  const handleChandeDiscount = useCallback((itemBag: ItemBag, value) => {
    dispatch(Actions.addBagDiscountItem({
      ...itemBag, 
      discount: +value
    }));
  }, [list]);

  const handleChandeDiscountGeral = useCallback((value) => {
    setDiscountGeral(value);
  }, [discountGeral]);

  const handleChandeTotal = useCallback((itemBag: ItemBag, value) => {
    dispatch(Actions.addBagTotalItem({
      ...itemBag, 
      total: value
    }));
  }, [list]);

  const handleMinus = useCallback((itemBag: ItemBag) => {
    const newQtd = itemBag?.qtd - 1;
    if(newQtd > 0 ) {
      dispatch(Actions.addBagQtdItem({
        ...itemBag, 
        qtd: newQtd
      }));
    }else{
      dispatch(Actions.addBagQtdItem({
        ...itemBag, 
        qtd: 1
      }));
    }
  }, [list]);

  const handlePlus = useCallback((itemBag: ItemBag) => {
    const newQtd = itemBag?.qtd + 1;
    const qtdValide = newQtd <= itemBag.item.total_disponivel_loja ? newQtd : itemBag.item.total_disponivel_loja;
    dispatch(Actions.addBagQtdItem({
      ...itemBag, 
      qtd: qtdValide
    }));
  }, [list]);

  const handleChandeQtd = useCallback((itemBag: ItemBag, value) => {
    if(value > 0 ) {
      const qtdValide = value <= itemBag.item.total_disponivel_loja ? value : itemBag.item.total_disponivel_loja;
      dispatch(Actions.addBagQtdItem({
        ...itemBag, 
        qtd: qtdValide
      }));
    }else{
      dispatch(Actions.addBagQtdItem({
        ...itemBag, 
        qtd: 1
      }));
    }
  }, [list]);
  
  const handleRemove = useCallback((itemBag: ItemBag)=> {
    dispatch(Actions.removeProduct(itemBag.item));
  }, [list]);

  const toggleSimulate = useCallback(() => {
    setShowSimulate(old => !old);
  }, [showSimulate])

  const handleSimulate = useCallback(()=> {
    if(!!list.length){ // Só abrir a simulação se houver produtos no carrinho
      handleClose();
      setShowSimulate(true);
      handleIsNewSimulate(false);
    }
  }, [list]);

  useEffect(() => {
    dispatch(Actions.addBagDiscountGeral(valueDiscountGeral));
  }, [valueDiscountGeral]);

  return (
    <>
    <Drawer full size="lg" show={show} onHide={handleClose}>
      <Drawer.Header>
        <Drawer.Title><h1>Carrinho</h1></Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid>
          <FlexboxGrid.Item
            colspan={24}
            style={{
              ...styleFlexEnd,
              marginBottom: 20,
            }}
          >
            <FormGroup style={{marginRight: 15}}>
              <ControlLabel style={{fontSize: 13, color:"#333"}}>Desconto Geral</ControlLabel>
              <InputGroup>
                  <InputNumber
                    className={'custom-input-number'}
                    max={100}
                    min={0}
                    value={discountGeral}
                    onChange={(value, event) => handleChandeDiscountGeral(value)}
                    onFocus={(e) =>  e.target.select()}
                    onClick={(e) =>  e.target.select()}
                  />
              </InputGroup>
            </FormGroup>
            <FormGroup style={{marginRight: 15}}>
              <ControlLabel style={{fontSize: 13, color:"#333"}}>Total</ControlLabel>
              <InputGroup>
                  <InputNumber
                    className={'custom-input-number'}
                    min={0}
                    value={total_value}
                  />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <ControlLabel style={{fontSize: 13, color:"#333"}}>Total carrinho c/ descontos</ControlLabel>
              <InputGroup>
                  <InputNumber
                    className={'custom-input-number'}
                    value={total_final} 
                  />
              </InputGroup>
            </FormGroup>
          </FlexboxGrid.Item>
        </FlexboxGrid>
          <List hover>
          {list.map((itemBag: ItemBag, index) => (
            <List.Item key={itemBag.item.codigo_produto} index={index}>
              <FlexboxGrid>
                <FlexboxGrid.Item
                  colspan={6}
                  style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'hidden'
                  }}
                >
                  <div>{itemBag.item?.descricao}</div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  colspan={2}
                  style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'hidden'
                  }}
                >
                  <div>{`R$ ${itemBag.item?.preco.toFixed(2)}`}</div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  colspan={16}
                  style={{
                    ...styleFlexEnd
                  }}
                >
                  <div style={{ maxWidth: 120, marginRight: 15 }}>
                    <FormGroup>
                      <ControlLabel style={{display: "block", fontSize: 12, color:"#777"}}>Quantidade</ControlLabel>
                      <InputGroup>
                        <InputGroup.Button onClick={() => handleMinus(itemBag)}>-</InputGroup.Button>
                          <InputNumber
                            className={'custom-input-number'}
                            size="sm" 
                            max={itemBag.item?.total_disponivel_loja} 
                            min={0}
                            value={itemBag?.qtd}
                            onChange={(value, event) => handleChandeQtd(itemBag, value)}
                            onFocus={(e) =>  e.target.select()}
                            onClick={(e) =>  e.target.select()}
                          />
                        <InputGroup.Button onClick={() => handlePlus(itemBag)}>+</InputGroup.Button>
                      </InputGroup>
                    </FormGroup>
                  </div>
                  <div style={{ maxWidth: 120, marginRight: 15 }}>
                    <FormGroup>
                      <ControlLabel style={{display: "block", fontSize: 12, color:"#777"}}>Desconto</ControlLabel>
                      <InputGroup>
                        <InputNumber
                          className={'custom-input-number'}
                          size="sm" 
                          postfix="%" 
                          max={100}
                          min={0}
                          value={itemBag?.discount}
                          onChange={(value, event) => handleChandeDiscount(itemBag, value)}
                          onFocus={(e) =>  e.target.select()}
                          onClick={(e) =>  e.target.select()}
                        />
                      </InputGroup>
                    </FormGroup>
                  </div>
                  <div style={{ maxWidth: 200, marginRight: 15 }}>
                    <FormGroup>
                      <ControlLabel style={{display: "block", fontSize: 12, color:"#777"}}>Valor total c/ desconto</ControlLabel>
                      <InputGroup>
                        <InputNumber
                          className={'custom-input-number'}
                          size="sm" 
                          postfix="R$" 
                          max={itemBag?.item.preco * itemBag?.qtd}
                          min={1}
                          value={itemBag?.total}
                          onChange={(value, event) => handleChandeTotal(itemBag, value)}
                          onFocus={(e) =>  e.target.select()}
                          onClick={(e) =>  e.target.select()}
                        />
                      </InputGroup>
                    </FormGroup>
                  </div>
                  <Whisper placement="top" trigger="hover" speaker={tooltipExcluir}>
                    <IconButton 
                      style={{marginRight: 15}}
                      tabIndex={0} 
                      size="sm" 
                      color="orange" 
                      icon={<Icon icon="trash2" />} 
                      onClick={() => handleRemove(itemBag)} 
                    >
                        Excluir
                    </IconButton>
                  </Whisper>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
          ))}
        </List>
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={handleSimulate} appearance="primary" size="lg">
          Ver Simulação de Venda
        </Button>
        <Button onClick={handleClearBag} color="orange" size="lg">
          Limpar carrinho
        </Button>
        <Button onClick={handleClose} appearance="subtle" size="lg">
          Sair (Esc)
        </Button>
      </Drawer.Footer>
    </Drawer>
     <DrawerSimulate show={showSimulate} handleClose={toggleSimulate} isNewSimulate={isNewSimulate} />
    </>
  );
};

export default DrawerBag;
