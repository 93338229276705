import {
  PERMITIONS_LOAD,
  PERMITIONS_FETCH,
  PERMITIONS_CREATE,
  PERMITIONS_UPDATE,
  PERMITIONS_DELETE
} from '../../constants';

import { ActionTypes, Permition } from '../../types';
import permitions from '../actions/permitions';

type State = {
  loading: boolean;
  list: Permition[];
};

const defaultState: State = {
  loading: true,
  list: [],
};

const reducerPermition = (state = defaultState, action: ActionTypes) => {
  return (
    {
      [PERMITIONS_LOAD]: {
        ...state,
        loading: action.payload,
      },
      [PERMITIONS_FETCH]:{
        ...state,
        loading: false,
        list: action.payload,
      },
      [PERMITIONS_CREATE]: {
        ...state,
        loading: false,
        list: [...state.list, action.payload],
      },
      [PERMITIONS_UPDATE]: {
        ...state,
        loading: false,
        list: state.list.map(p => {
          if(p?.id === action.payload?.id){
            return action.payload;
          }else{
            return p;
          }
        })
      },
      [PERMITIONS_DELETE]: {
        ...state,
        loading: false,
        list: [...state.list.filter(permition => permition?.id !== action.payload?.id)],
      },
    }[action.type] || state
  );
};

export default reducerPermition;
