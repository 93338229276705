import React from 'react';
import { Table, TableCellProps } from 'rsuite';

const { Cell } = Table;

interface Props extends TableCellProps {
  validateMessage?: string;
  invalidateMessage?: string;
}

const CellBoll: React.FC<Props> = ({
  validateMessage,
  invalidateMessage,
  rowData,
  dataKey,
  ...props
}) => {

  return (
    <Cell {...props} className="link-group">
      {rowData[dataKey] ? validateMessage : invalidateMessage}
    </Cell>
  );
};

export default CellBoll;
