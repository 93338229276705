import React from 'react';
import { Icon } from 'rsuite';
import { Link } from 'react-router-dom';

const ButtonConfig: React.FC = () => {
  return (
    <Link to="/admin/userconfig" style={{textDecoration: "none"}}>
      <Icon icon="setting" style={{marginRight: 5 }}/>
      Ajustes
      </Link>
  );
};

export default ButtonConfig;