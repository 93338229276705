import api from './api.service';
import { Report, ReportState } from '../types';

const all = async (search: string = ''): Promise<Report[]> => {
  const { data } = await api.get<Report[]>(`/reports?search=${search}`);
  return data;
};

const create = async (report: Report): Promise<Report> => {
  const { data } = await api.post<Report>(`/reports`, report);
  return data;
};

const update = async (report: Report): Promise<Report> => {
  const { data } = await api.put<Report>(`/reports/${report?.id}`, report);
  return data;
};

const del = async (report: ReportState): Promise<void> => {
  await api.delete(`/reports/${report?.id}`);
};

export default {
  all,
  create,
  update,
  del,
};
