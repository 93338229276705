import React, { useEffect, useState, useCallback, useRef, ChangeEvent } from "react";
import { 
  Panel,
  Tooltip,
  Whisper,
  Loader,
  IconButton,
  Icon,
  Modal,
  Divider,
  Table,
  TableCellProps
} from "rsuite";
import {useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "../hooks/store";
import { useEventListener } from "../hooks/event";

import productServer from '../services/romaneioProduct.services'

import { State, Romaneio } from "../types";
import { openNotification } from "../utils";
import actions from "../store/actions";

const addProductTooltip = <Tooltip>Pesquisar produdo: Informe o ID do produto de precione ENTER</Tooltip>;
const goBackTip = <Tooltip>Voltar para Romaneios</Tooltip>;
const generateRomaneio = <Tooltip>Gerar Romaneio para impressão</Tooltip>;

const { Column, HeaderCell, Cell } = Table;

const GoToCell = ({ rowData, handleRemoveProduct, dataKey, ...props }:TableCellProps) => (
  <Cell {...props}>
      <IconButton 
      style={{cursor: "pointer", marginRight: 10}}
      onClick={() => handleRemoveProduct(rowData["id"])} 
      appearance="default" 
      color="red"
      icon={<Icon icon="trash2" />} placement="right"
    >
      Remover
    </IconButton>
  </Cell>
);

const CellGrid = ({ rowData, dataKey, ...props }:TableCellProps) => (
  <Cell {...props}>
    { rowData["grid"]
      .map( g => (
        <span style={{marginRight: 5}}><strong>{g.name}: </strong>{g.quantity}</span>
        )
      ) }
  </Cell>
);

const PageRomaneio = () => {
  const { loading, products } = useSelector((state: State) => state?.romaneioProducts);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingSaveProduct, setLoadingSaveProduct] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [productSearch, setProductSearch] = useState(null);
  const [formData, setFormData] = useState<{[key:string]: number}>({});
  const [search, setSearch] = useState('');
  const inputSearch = useRef(null);
  const dispatch = useDispatch();
  const { push } = useHistory()
  const { state: romaneio, pathname} = useLocation<Romaneio>();

   useEffect(() => {
    inputSearch.current.focus()

    dispatch(actions.handleReceveRomaneioProducts(romaneio.id))
  },[]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false)
    setFormData({})
  }, [showModal])
  
  const handleSearchProduct = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    setSearch(value)
  }, [search])

  const handleChandeGrid = useCallback((event: ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = event.target;
    setFormData(data => Object.assign(data, {}, { [name]: value }))
  }, [formData]);

  const handleChandeDetails = useCallback((event: ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = event.target;
    setFormData(data => Object.assign(data, {}, { details: value }))
  }, [formData]);

  const handleKeyDownEnter = useCallback( async (event) => {
    event.preventDefault();

      if (event.keyCode === 13) { 
          setLoadingSearch(true)
          const id = inputSearch.current.value;

          // verificar se produto já foi add no romaneio
          const exist = products.findIndex(product => product.id === Number(id))

          if(exist !== -1) {
            // notificar que produto já foi add
            openNotification('warning', 'Busca de produto', 'O produto já foi adicionado no romaneio.', 1200)
            setLoadingSearch(false)
            setSearch('')
            return
          }
          
          const prod = await productServer.show({ id });

          if(prod){
            setProductSearch(prod)
            setSearch('')
            setShowModal(true)
            setLoadingSearch(false)
          }else{
            setLoadingSearch(false)
            openNotification("error", "Falha", "Produto não localizado!", 2000);
          }
      }
  }, [products]);

  useEventListener('keyup', handleKeyDownEnter, inputSearch);

  const handleSaveProduct = useCallback(async (event) => {
    try {
      event.preventDefault()
    
      const data = formData;
      setShowModal(false)
      setLoadingSaveProduct(true)

      // Map grid
      const body = productSearch.grid.reduce((accumulator, value) => {
        return {
          ...accumulator,
          grid: [
            ...accumulator.grid,
             {
               name: value.name,
               quantity: data[value.name] | 0
             }
          ],
        }
      }, {id: productSearch.id, details: data?.details,  grid:[]})

      dispatch(actions.handleSaveRomaneioProduct({romaneioId: romaneio.id, body}))
    } catch (error) { 
      openNotification("error", "Salvando Grade", "Falha ao salvar a grade, tente novamente!", 2000);
     } finally {
      setFormData({})
      setLoadingSaveProduct(false)
    }
  },[formData, productSearch, romaneio])

  const handleRemoveProduct = useCallback((id) => {
    dispatch(actions.handleRemoveRomaneioProduct({romaneioId: romaneio.id, id}))
  }, [romaneio])

  const handlePDFGenerator = useCallback(() => {
    push(`/admin/romaneios/${romaneio.id}/print?origin=${romaneio.store_origin}&destination=${romaneio.store_destination}`)
  }, [romaneio])
 
  const renderHeader = () => (
    <div className="search-table">
      <div className="search-tools">
        <div style={{display: 'flex', justifyContent: `flex-start`, alignItems: 'center'}}>
          <h2>Romaneio</h2>
          <span style={{marginLeft: 15, marginBottom: '-10px'}}><strong>Origem: </strong>Loja {romaneio?.store_origin}</span>
          <span style={{marginLeft: 15, marginBottom: '-10px'}}><strong>Destino: </strong>Loja {romaneio?.store_destination}</span>
        </div>

        <div>
        <Whisper placement="top" trigger="hover" speaker={goBackTip}>
          <IconButton 
            style={{cursor: "pointer", marginLeft: 20}}
            onClick={() => push('/admin/romaneios')} 
            abIndex={0} 
            appearance="default" 
            icon={<Icon icon="back-arrow" />} placement="left"
          >
            Voltar
          </IconButton>
        </Whisper>
        <Whisper placement="top" trigger="hover" speaker={generateRomaneio}>
          <IconButton 
            style={{cursor: "pointer", marginLeft: 20}}
            onClick={handlePDFGenerator} 
            abIndex={0} 
            appearance="default" 
            color="green"
            icon={<Icon icon="print" />} placement="right"
          >
            Gerar Romaneio
          </IconButton>
        </Whisper>
        </div>
      </div>
      <div className="form-group-search" style={{marginTop: 10}}>
        <Whisper
          trigger="hover"
          placement="topStart"
          speaker={addProductTooltip}
        >
          <input
            style={{height: 45}}
            ref={inputSearch}
            placeholder="Busque por produtos aqui!"
            type="text"
            className="rs-input rs-input-group-lg"
            onChange={handleSearchProduct}
            value={search}
            tabIndex={0}
          />
        </Whisper>
      </div>
    </div>
  );

  return (
    <Panel header={renderHeader()} id={'panelRomaneio'}>
      {(loading && !loadingSaveProduct) && (<Loader backdrop center content="Carregando produtos..."/>)}
      {loadingSearch && (<Loader backdrop center content="Buscando produto..."/>)}
      {loadingSaveProduct && (<Loader backdrop center content="Salvando produto..."/>)}

        <Table 
          fluid
          data={products} 
          style={{height: '100%', cursor: 'pointer'}}
          rowHeight={() => 55} 
          renderEmpty={() => (
            <div 
              className="rs-table-body-info" 
              style={{display: `${loading ? 'none': 'block'}`}}>
                Nenhum produto foi encontrato.
            </div>
          )}
        >
            <Column flexGrow={0.5} align="left">
              <HeaderCell>id</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column flexGrow={1} align="left">
              <HeaderCell>Descrição</HeaderCell>
              <Cell dataKey="description" />
            </Column>
            <Column flexGrow={1} align="left">
              <HeaderCell>Grade</HeaderCell>
              <CellGrid dataKey="grid" />
            </Column>
            <Column flexGrow={1} align="left">
              <HeaderCell>Ação</HeaderCell>
              <GoToCell 
                dataKey="store_destination"
                handleRemoveProduct={handleRemoveProduct}
            />
        </Column>
          </Table>
        <Modal backdrop="static" show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Body>
          <form className="rs-form rs-form-horizontal" onSubmit={handleSaveProduct}>
            <h3>{productSearch?.id} - {productSearch?.description}</h3>
            <Divider>Grades</Divider>
            {productSearch?.grid.map((g, i) => (
              <div className="rs-form-group" role="group" key={g.name}>
                <label className="rs-control-label">{g.name}</label>
                <div className="rs-form-control-wrapper">
                  <input autoFocus={i === 0} name={g.name} type="text" className="rs-input" value={formData[g.name]} onChange={(e) => handleChandeGrid(e, g.name)} />
                </div>
              </div>
            ))}

            <div className="rs-form-group" role="group">
              <label className="rs-control-label">Observações</label>
              <div className="rs-form-control-wrapper">
                <textarea 
                  className="rs-input" 
                  rows={5} 
                  name="description" 
                  value={formData.details} 
                  maxLength={30}  
                  onChange={handleChandeDetails} 
                />
              </div>
            </div>
            
            <div className="rs-form-group" role="group">
              <div role="toolbar" className="rs-btn-toolbar">
                <button type="button" className="rs-btn rs-btn-primary" onClick={handleSaveProduct}>
                  Salvar<span className="rs-ripple-pond"><span className="rs-ripple"></span></span>
                </button>
                <button type="button" className="rs-btn rs-btn-default" onClick={handleCloseModal} >
                  Cencelar<span className="rs-ripple-pond"><span className="rs-ripple"></span></span> 
                </button>
              </div>  
            </div>
          </form>
          </Modal.Body>
        </Modal>
    </Panel>
  );
};

export default PageRomaneio;
