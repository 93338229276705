import api from './api.service';
import { Stock } from '../types';

const getStock = async (id: number): Promise<Stock[]> => {
  const query = `/productstock/${id}`;
  const response = await api.get<Stock[]>(query);
  return response?.data;
};

export default {
  getStock
};
