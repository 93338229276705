import api from './api.service';
import { Permition } from '../types';

const all = async (): Promise<Permition[]> => {
  const { data } = await api.get<Permition[]>(`/permitions`);
  
  return Promise.resolve(data);
};

const create = async (permition: Permition): Promise<Permition> => {
  const { data } = await api.post<Permition>(`/permitions`, permition);
  
  return Promise.resolve(data);
};

const update = async (permition: Permition): Promise<Permition> => {
  const { data } = await api.put<Permition>(`/permitions/${permition.id}`, permition);
  
  return Promise.resolve(data);
};

const remove = async (permition: Permition): Promise<void> => {
  await api.delete(`/permitions/${permition.id}`);
};

export default {
  all,
  create,
  update,
  remove
}
