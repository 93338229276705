import {
  ROLES_LOAD,
  ROLES_FETCH
} from '../../constants';

import { ActionTypes, Role } from '../../types';

type State = {
  loading: boolean;
  list: Role[];
};

const defaultState: State = {
  loading: true,
  list: [],
};

const reducerRole = (state = defaultState, action: ActionTypes) => {
  return (
    {
      [ROLES_LOAD]: {
        ...state,
        loading: action.payload,
      },
      [ROLES_FETCH]:{
        ...state,
        loading: false,
        list: action.payload,
      },
    }[action.type] || state
  );
};

export default reducerRole;
