import { Dispatch } from 'redux';
import interesttaxServiceProvider from '../../services/interesttax.services';

import * as C from '../../constants';
import { InterestTax } from '../../types';

export const loadInterestTax = (active = true) => ({
  type: C.INTEREST_TAX_LOAD,
  payload: active,
});

export const fetchInterestTax = (interestTax: InterestTax[]) => ({
  type: C.INTEREST_TAX_FETCH,
  payload: interestTax,
});

export const receiveInterestTax = () => ({
  type: C.INTEREST_TAX_LIST,
});

export const updateInterestTax = (interestTax: InterestTax) => ({
  type: C.INTEREST_TAX_UPDATE,
  payload: interestTax,
});

export const createInterestTax = (interestTax: InterestTax) => ({
  type: C.INTEREST_TAX_CREATE,
  payload: interestTax,
});

export const deleteInterestTax = (id) => ({
  type: C.INTEREST_TAX_DELETE,
  payload: id,
});

export const handleFetchInterestTax = () => async (dispatch: Dispatch) => {
  try { 
    dispatch(loadInterestTax());
    const interestTax = await interesttaxServiceProvider.all();
    dispatch(fetchInterestTax(interestTax));
  } catch (error) {
    dispatch(loadInterestTax(false));
    console.error(error);
  }
};

export const handleUpdateInterestTax = (interestTax: InterestTax) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadInterestTax());
    const newInterestTax = await interesttaxServiceProvider.update(interestTax);
    dispatch(updateInterestTax(newInterestTax));

  } catch (error) {
    dispatch(loadInterestTax(false));
    console.error(error);
  }
};

export const handleCreateInterestTax = (interestTax: InterestTax) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadInterestTax());
    const newInterestTax = await interesttaxServiceProvider.create(interestTax);
    dispatch(createInterestTax(newInterestTax));

  } catch (error) {
    dispatch(loadInterestTax(false));
    console.error(error);
  }
};

export const handleDeleteInterestTax = (interestTax: InterestTax) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadInterestTax());
    await interesttaxServiceProvider.del(interestTax);
    dispatch(deleteInterestTax(interestTax?.id));
  } catch (error) {
    dispatch(loadInterestTax(false));
    console.error(error);
  }
};

export default {
  loadInterestTax,
  fetchInterestTax,
  createInterestTax,
  updateInterestTax,
  deleteInterestTax,
  receiveInterestTax,
  handleFetchInterestTax,
  handleCreateInterestTax,
  handleUpdateInterestTax,
  handleDeleteInterestTax,
};
