import { combineReducers } from 'redux';

import reducerReport from './report';
import reducerInterestTax from './interesttax';
import reducerProduct from './product';
import reducerBag from './bag';
import navbar from './navbar';
import userconfig from './userconfig';
import roles from './roles';
import permitions from './permitions';
import romaneios from './romaneios';
import romaneioProducts from './romaneioProducts';

export default combineReducers({
  reports: reducerReport,
  interesttax: reducerInterestTax,
  products: reducerProduct,
  bag: reducerBag,
  navbar,
  userconfig,
  roles,
  permitions,
  romaneios,
  romaneioProducts,
});
