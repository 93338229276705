import React from 'react';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  FormControlProps,
} from 'rsuite';

const TextField: React.FC<FormControlProps> = ({
  name,
  label,
  required,
  accepter,
  ...props
}) => {
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl name={name} accepter={accepter} {...props} />
      {required && <HelpBlock>Obrigatório</HelpBlock>}
    </FormGroup>
  );
};

export default TextField;
