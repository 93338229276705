import api from './api.service';
import { RomaneioData, RomaneioProductData, RomaneioProducts } from '../types';

type Request = {
  romaneioId: number;
  body: RomaneioProductData 
}

type RequestRemove = {
  romaneioId: number;
  id: number
}

type Req = {
  id: number
}

export const all = async ({ id }:Req): Promise<RomaneioProducts> => {
  const response = await api.get<RomaneioProducts>(`/romaneios/${id}/products`);

  return response?.data;
};

export const show = async ({ id }:Req): Promise<RomaneioData> => {
  const response = await api.get<RomaneioData>(`/romaneios/products/${id}`);

  return response?.data;
};

export const save = async ({ romaneioId, body  }:Request): Promise<RomaneioProductData> => {
  try {
    const response = await api.post<RomaneioProductData>(`/romaneios/${romaneioId}/products`, body);
    
    return response?.data
  } catch (error) {
    throw error;
  }
};

export const remove = async ({romaneioId, id}:RequestRemove): Promise<void> => {
  await api.delete(`/romaneios/${romaneioId}/products/${id}`);
};

export default {
  show,
  save,
  all,
  remove
};
