import api from "./api.service";
import { Product } from "../types";

type Request = {
  id?: number;
  search?: string;
  limit?: number;
  page?: number;
};

const all = async ({
  id = 0,
  search = "",
  limit = 50,
  page = 1,
}: Request): Promise<Product[]> => {
  const query = `/products?id=${id}&search=${search}&limit=${limit}&page=${page}`;
  const { data } = await api.get<Product[]>(query);
  return parseGarantia(data);
};

const parseGarantia = (products: Product[]) => {
  return products?.map((product) => ({
    ...product,
    garantia_extendida: JSON.parse(
      (product?.garantia_extendida as unknown as string) || "[]"
    ),
  }));
};

export default {
  all,
};
