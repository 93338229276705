import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  FormControlProps,
  InputPicker,
} from 'rsuite';
import { InputPickerData } from '../../../types';
import { normalizeSalespeople } from '../../../utils';
import api from '../../../services/api.service';

const InputPickerSalespeople: React.FC<FormControlProps> = ({ store }) => {
  const [salespeople, setSalespeople] = useState<InputPickerData[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/salespeople/${store}`);
      setSalespeople(normalizeSalespeople(data || []));
    })();
  }, [store]);

  return (
    <FormGroup>
      <ControlLabel>Vendedores</ControlLabel>
      <FormControl name="VD" accepter={InputPicker} data={salespeople} />
    </FormGroup>
  );
};

export default InputPickerSalespeople;
