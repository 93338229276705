import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AuthServices from '../services/auth.services';
import { AuthContextData, User, loginRequest, LocationState, UserConfig } from '../types';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(() => {
    const storagedUser = AuthServices.getCurrentUser();
    return storagedUser;
  });

  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation<LocationState>();

  useEffect(() => {
    async function loadStorageData() {
      const storagedUser = await AuthServices.getCurrentUser();
      setUser(storagedUser);
      setLoading(false);
    }

    loadStorageData();
  }, []);

  async function setConfig(config: UserConfig ) {
    setLoading(true);
    const storagedUser = await AuthServices.getCurrentUser();
    const UpdateUser = AuthServices.updateUser({
      ...storagedUser,
      config: {
        ...storagedUser.config,
        ...config
      }
    });
    setUser(UpdateUser);
    setLoading(false);
  }

  async function signIn({ username, password }: loginRequest) {
    setLoading(true);
    const response = await AuthServices.login({ username, password });
    setUser(response);
    setLoading(false);
    let { from } = location.state || { from: { pathname: '/' } };
    history.replace(from);
  }

  async function signOut() {
    await AuthServices.logout();
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{ signed: !!user, loading, user, signIn, signOut, setConfig }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

export { AuthProvider, useAuth };
