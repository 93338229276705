import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Drawer,
  Button,
  Schema,
  Divider,
  Form,
  FormProps,
} from 'rsuite';
import { InterestTax } from '../../../types';
import TextField from '../../Form/TextField';

const { NumberType } = Schema.Types;

const SchemaForm = Schema.Model<InterestTax>({
  quantity: NumberType().isRequired('Campo obrigatório'),
  tax: NumberType().isRequired('Campo obrigatório')
});

const DrawerEdit: React.FC<{
  interesttax: InterestTax;
  show: boolean;
  handleClose: () => void;
  handleUpdate: (tax: InterestTax) => void;
}> = ({ interesttax, show, handleClose, handleUpdate }) => {
  const formRef = useRef<FormProps>(null);
  const [formData, setFormData] = useState<InterestTax>(null);

  const handleChange = (data: InterestTax) => {
    setFormData(data);
  };

  const handleSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();

      if (formRef.current.check()) {
        setFormData(null);
        handleUpdate(formData);
      }
    },
    [formData, handleUpdate]
  );

  useEffect(() => {
    if (interesttax) {
      setFormData(interesttax);
    }
  }, [interesttax, show]);

  return (
    <Drawer size="lg" show={show} onHide={handleClose}>
      <Drawer.Header>
        <Drawer.Title>Editando</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <h3>Editar fator de parcelamento</h3>
        <Divider />
        <Form
          fluid
          onChange={handleChange}
          model={SchemaForm}
          ref={formRef}
          formValue={formData}
        >
          <TextField name="quantity" label="Parcela" required />
          <TextField name="tax" label="Fator" required/>
        </Form>
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={handleSubmit} appearance="primary">
          Salvar alterações
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
};

export default DrawerEdit;
