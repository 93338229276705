import React, { useEffect, useState, useCallback } from "react";
import { 
  Table, 
  Panel, 
  Button, 
  Icon, 
  Modal
} from "rsuite";
import { useDispatch, useSelector } from "../hooks/store";
import Actions from "../store/actions";
import {sortedInterestTax as sorted} from "../utils"

import ActionCell from "../components/Table/ActionCell";
import { State, InterestTax } from "../types";
import DrawerEdit from "../components/InterestTax/DrawerEdit";
import DrawerNew from "../components/InterestTax/DrawerNew";

const { Cell, Column, HeaderCell } = Table;

const Tax: React.FC = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [interesttax, setInteresttax] = useState<InterestTax>(null);
  const [popup, setPopup] = useState(false);
  const dispatch = useDispatch();
  const { loading, list } = useSelector((state: State) => state?.interesttax);

  const toggleNew = useCallback(() => {
    setShowNew((old) => !old);
  },[]);


  const togglePopUp = async () => {
    setPopup((old) => !old);
  };

  const toggleEdit = useCallback(() => {
    setShowEdit((old) => !old);
  },[]);

  const openShowEdit = useCallback((interesttax: InterestTax) => {
    setInteresttax(interesttax);
      toggleEdit();
    },
    [toggleEdit],
  );

  const openDelete = useCallback((interesttax: InterestTax) => {
    setInteresttax(interesttax);
    togglePopUp();
  }, []);

  const handleSaveInterestTax = useCallback((data: InterestTax) => {
    dispatch(Actions.handleCreateInterestTax(data));
    toggleNew();
  },[dispatch, toggleNew]);

  const handleUpdateInterestTax = useCallback(async (data: InterestTax) => {
    dispatch(Actions.handleUpdateInterestTax(data));
    toggleEdit();
  },[dispatch, toggleEdit]);

  const handleDelete = useCallback(() => {
    togglePopUp();
    dispatch(Actions.handleDeleteInterestTax(interesttax));
  }, [dispatch, interesttax]);

  useEffect(() => {
    dispatch(Actions.handleFetchInterestTax());
  }, [dispatch]);

  const renderHeaderTable = () => (
    <div className="search-table">
      <h2>Fator de calculo de parcelas</h2>
      <div
        className="rs-input-group rs-input-group-lg rs-input-group-inside"
        style={{ marginTop: 15 }}
      >
        <Button
          appearance="primary"
          size="lg"
          style={{ marginRight: 15 }}
          onClick={toggleNew}
        >
          Novo <Icon icon="save" />
        </Button>
      </div>
    </div>
  );

  return (
    <Panel header={renderHeaderTable()}>
      <Table height={480} data={sorted(list)} loading={loading}>
        <Column width={80}>
          <HeaderCell>Parcela</HeaderCell>
          <Cell dataKey="quantity" />
        </Column>

        <Column width={150}>
          <HeaderCell>Taxa</HeaderCell>
          <Cell dataKey="tax" />
        </Column>
        <Column width={150}>
          <HeaderCell>Ações</HeaderCell>
          <ActionCell
            dataKey={"id"}
            openEdit={openShowEdit}
            openView={null}
            handleDelete={openDelete}
          />
        </Column>
      </Table>
      <DrawerNew
        show={showNew}
        handleClose={toggleNew}
        handleSalve={handleSaveInterestTax}
      />
      <DrawerEdit
        interesttax={interesttax}
        show={showEdit}
        handleClose={toggleEdit}
        handleUpdate={handleUpdateInterestTax}
      />
      <Modal backdrop="static" show={popup} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Tem certeza de que quer excluir registro?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleDelete()} appearance="primary">
            Sim
          </Button>
          <Button onClick={() => togglePopUp()} appearance="subtle">
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal> 
    </Panel>
  );
};

export default Tax;
