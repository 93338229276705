import api from './api.service';
import { Paginate, Romaneio, RomaneioParams } from '../types';

type Request = {
  origin?: number;
  destination?: number;
  limit?: number;
  page?: number;
  romaneioId?: number;
}

export const index = async ({ origin, destination, limit = 50, page = 1, romaneioId }:Request): Promise<Paginate<Romaneio>> => {
  let query = `/romaneios?limit=${limit}&page=${page}`;

  if(romaneioId) { query += `&romaneioId=${romaneioId}`; }
  
  if(origin){ query += `&origin=${origin}`; }
  
  if(destination){ query += `&destination=${destination}`; }
  
  const { data } = await api.get(query);
  return data;
};

export const save = async ({ store_origin, store_destination }: RomaneioParams): Promise<Romaneio> => {
  const { data } = await api.post(`/romaneios`, { store_origin, store_destination });
  return data;
};

export const remove = async (id: number): Promise<void> => {
  await api.delete(`/romaneios/${id}`);
};

export default {
  index,
  save,
  remove
};
