import React, { useState, useCallback, useRef, useEffect } from 'react';
import { 
  Button, 
  Schema, 
  Container, 
  Panel, 
  FormProps, 
  Form, 
  FormGroup, 
  FormControl, 
  ControlLabel,  
  HelpBlock 
} from 'rsuite';
import { useAuth } from '../hooks/auth';
import { loginRequest } from '../types';

import logoFeirao from '../assets/logo_feirao_dos_moveis.svg';

const { StringType } = Schema.Types;

type TLogin = {
  username: string,
  password: string
}

const SchemaForm = Schema.Model<TLogin>({
  username: StringType().isRequired('O usuário é obrigatório'),
  password: StringType().isRequired('Informe a senha'),
});

const Login: React.FC = () => {
  const { signIn, loading } = useAuth();
  const formRef = useRef<FormProps>(null);
  const [formData, setFormData] = useState<loginRequest>();

  const handleChange = useCallback((data: loginRequest) => {
    setFormData(data);
  }, []);

  const handleSubmit = useCallback(() => {
    if (formRef.current.check()) {
      signIn(formData);
    }
  }, [formData]);

  useEffect(() => {
    const inputUsername = document.querySelector<HTMLInputElement>("#username");
    inputUsername.focus();
  }, []);

  useEffect(() => {
    const inputPassword = document.querySelector<HTMLInputElement>("#password");

    const listenePassword = (e) => {
      if(e.keyCode === 13){
        handleSubmit();
      }
    }

    inputPassword.addEventListener('keydown', listenePassword);

    return () => {
      inputPassword.removeEventListener('keydown', listenePassword);
    }
  }, [formData]);

  return (
    <Container className="container-login">
      <Panel bordered>
        <div className="login-box">
          <img src={logoFeirao} alt="logo do feirão dos móveis" />
        <Form
          fluid
          onChange={handleChange}
          model={SchemaForm}
          ref={formRef}
          formValue={formData}
          autocomplete="off"
        >
          <FormGroup>
            <ControlLabel>Usuário</ControlLabel>
            <FormControl name="username" id="username"/>
            <HelpBlock>Obrigatório</HelpBlock>
          </FormGroup>
          <FormGroup>
              <ControlLabel>Senha</ControlLabel>
              <FormControl name="password" id="password" type="password"/>
              <HelpBlock>Obrigatório</HelpBlock>
            </FormGroup>
        </Form>
          <div className="login-box-control">
            <Button
              size="lg"
              appearance="primary"
              onClick={handleSubmit}
              disabled={loading}
              loading={loading}
            >
              {loading ? 'aguarde...' : 'Entrar'}
            </Button>
          </div>
        </div>
      </Panel>
    </Container>
  );
};

export default Login;
