import { Dispatch } from 'redux';
import useConfigServiceProvider from '../../services/userConfig.services';

import * as C from '../../constants';
import { UserConfig } from '../../types';

export const loadUserConfig = (active = true) => ({
  type: C.USER_CONFIG_LOAD,
  payload: active,
});

export const receiveUserConfig = () => ({
  type: C.USER_CONFIG
});

export const setUserConfig = (useConfig: UserConfig) => ({
  type: C.USER_CONFIG_SET,
  payload: useConfig,
});

export const updateUserConfig = (useConfig: UserConfig) => ({
  type: C.USER_CONFIG_UPDATE,
  payload: useConfig,
});

export const handleUpdateUserConfig = (useConfig: UserConfig) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadUserConfig());
    const config = await useConfigServiceProvider.update(useConfig);
    
    dispatch(updateUserConfig(config));
  } catch (error) {
    dispatch(loadUserConfig(false));
  }
};

export default {
  loadUserConfig,
  receiveUserConfig,
  updateUserConfig,
  handleUpdateUserConfig,
  setUserConfig
};
