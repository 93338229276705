import {
  NAVBAR_TOGGLE,
  NAVBAR,
  NAVBAR_CLOSE,
  NAVBAR_SET,
} from '../../constants';

import { ActionTypes } from '../../types';

type State = {
  expand: boolean,
}

const defaultState: State = {
  expand: true,
}

const reducerBag = (state = defaultState, action: ActionTypes) => {
  return (
    {
      [NAVBAR_TOGGLE]: {
        ...state,
        expand: !state.expand
      },
      [NAVBAR]: state,
      [NAVBAR_CLOSE]: {
        ...state,
        expand: false,
      },
      [NAVBAR_SET]: {
        ...state,
        expand: action.payload
      }
    }[action.type] || state
  );
};

export default reducerBag;
