import React, { useState } from 'react';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  FormControlProps,
  InputPicker,
} from 'rsuite';
import { InputPickerData } from '../../../types';

const InputPickerFilter: React.FC<FormControlProps> = () => {
  const [ filters ] = useState<InputPickerData[]>(() => {
    return [
      { label: "Apenas com saldo", value: 0 },
      { label: "Todos os contratos", value: 1 },
    ]
  });

  return (
    <FormGroup>
      <ControlLabel>Filtrar por saldo</ControlLabel>
      <FormControl name="FILT" accepter={InputPicker} data={filters} />
    </FormGroup>
  );
};

export default InputPickerFilter;
