import { Dispatch } from 'redux';
import romaneioServiceProvider from '../../services/romaneioProduct.services';

import * as C from '../../constants';
import { RomaneioProducts, RomaneioProductData } from '../../types';

type Req = {
  romaneioId: number
  body: RomaneioProductData
}

type ReqDelete = {
  romaneioId: number
  id: number
}

export const loadRomaneioProducts = (payload = true) => ({
  type: C.ROMANEIO_PRODUCTS_LOAD,
  payload,
});

export const receiveRomaneioProduct = (payload: RomaneioProducts) => ({
  type: C.ROMANEIO_PRODUCTS_FETCH,
  payload,
});

export const setRomaneioProduct = (payload: RomaneioProductData) => ({
  type: C.ROMANEIO_PRODUCTS_SAVE,
  payload,
});

export const removeRomaneioProduct = (payload: number) => ({
  type: C.ROMANEIO_PRODUCTS_REMOVE,
  payload,
});

export const handleReceveRomaneioProducts = (id: number) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadRomaneioProducts());
    const products = await romaneioServiceProvider.all({ id });
    
    dispatch(receiveRomaneioProduct(products));
  } catch (error) {
    dispatch(loadRomaneioProducts(false));
  }
};

export const handleSaveRomaneioProduct = ({romaneioId, body}: Req) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadRomaneioProducts());
    const product = await romaneioServiceProvider.save({romaneioId, body});
    dispatch(setRomaneioProduct(product));
  } catch (error) {
    dispatch(loadRomaneioProducts(false));
    throw error;
  }
};

export const handleRemoveRomaneioProduct = ({romaneioId, id }:ReqDelete) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadRomaneioProducts());
    await romaneioServiceProvider.remove({romaneioId, id});
    
    dispatch(removeRomaneioProduct(id));
  } catch (error) {
    dispatch(loadRomaneioProducts(false));
  }
};

export default {
  loadRomaneioProducts,
  receiveRomaneioProduct,
  setRomaneioProduct,
  removeRomaneioProduct,
  handleReceveRomaneioProducts,
  handleSaveRomaneioProduct,
  handleRemoveRomaneioProduct
};
