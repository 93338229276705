import {
  USER_CONFIG,
  USER_CONFIG_LOAD,
  USER_CONFIG_UPDATE,
  USER_CONFIG_SET
} from '../../constants';

import { ActionTypes, UserConfig } from '../../types';

type State = {
  loading: boolean;
  config: UserConfig;
};

const defaultState: State = {
  loading: true,
  config: null,
};

const reducerInterestTax = (state = defaultState, action: ActionTypes) => {
  return (
    {
      [USER_CONFIG_LOAD]: {
        ...state,
        loading: action.payload,
      },
      [USER_CONFIG_SET]: {
        ...state,
        loading: false,
        config: {
          ...action.payload
        }
      },
      [USER_CONFIG]: {
        ...state,
        loading: false,
      },
      [USER_CONFIG_UPDATE]: {
        ...state,
        loading: false,
        config: {
          ...action.payload
        }
      },
    }[action.type] || state
  );
};

export default reducerInterestTax;
