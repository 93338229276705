import React from "react";

type Props = {
  pdf: any
}

const PDFRomaneio = ({
  pdf
}: Props) => {

  return (
    <div className="printer">
      {pdf && (
              <iframe id="romaneiopdf" name="romaneiopdf" src={pdf} style={{ width: "100%", height: "100%" }} />
            )}
    </div>
  );
};

export default PDFRomaneio;
