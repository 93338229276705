import React, {
  useEffect,
  useState,
  FormEvent,
  useCallback,
  useRef,
} from "react";
import {
  Table,
  Panel,
  Icon,
  IconButton,
  Whisper,
  TableCellProps,
  Tooltip,
  Badge,
  Notification,
  Popover,
  Modal,
  Button,
  Schema,
  Form,
  FormGroup,
  FormProps,
  HelpBlock,
  Divider,
} from "rsuite";
import { useDispatch, useSelector } from "../hooks/store";
import Actions from "../store/actions";
import { useHistory } from "react-router-dom";

import { State, SimpleProduct, Stock, UserConfig, Garantia } from "../types";
import DrawerBag from "../components/product/DrawerBag";
import DrawerView from "../components/product/DrawerView";
import InputPickerStore from "../components/Form/InputPickerStore";
import { garantiaToArrayFormated, isNumber } from "../utils";
import { useAuth } from "../hooks/auth";

const { Cell, Column, HeaderCell } = Table;

const { NumberType } = Schema.Types;

const SchemaForm = Schema.Model<UserConfig>({
  store: NumberType(),
});

type Config = {
  store: number;
};

const PriceCell = ({ rowData, dataKey, ...props }: TableCellProps) => (
  <Cell {...props}>{`R$ ${parseFloat(rowData[dataKey]).toFixed(2)}`}</Cell>
);

const CellId = ({ rowData, dataKey, ...props }: TableCellProps) => (
  <Cell {...props}>
    <span style={{ fontSize: 18, fontWeight: "bold" }}>{rowData[dataKey]}</span>
  </Cell>
);

const speaker = (
  <Popover title="Informação">
    <p>Estoque total disponível para venda de todas as lojas</p>
  </Popover>
);

const speakerAvisoGarantia = (
  <Popover title="Informação">
    <p>
      Importante confirmar no produto o prazo da{" "}
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        garantia do fabricante
      </span>{" "}
      e em caso de divergência informar o{" "}
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        Suporte
      </span>
      .
    </p>
  </Popover>
);

const CellStock = ({ rowData, dataKey, ...props }: TableCellProps) => (
  <Cell {...props}>
    {rowData[dataKey] === 0 ? (
      <Whisper placement="top" speaker={speaker} trigger="hover">
        <span style={{ fontSize: 15, fontWeight: "bold", color: "#f44336" }}>
          {rowData[dataKey]}
        </span>
      </Whisper>
    ) : (
      <Whisper placement="top" speaker={speaker} trigger="hover">
        <span style={{ fontSize: 15, fontWeight: "bold", color: "#4caf50" }}>
          {rowData[dataKey]}
        </span>
      </Whisper>
    )}
  </Cell>
);

const CellStockGarantiaFabricante = ({
  rowData,
  dataKey,
  ...props
}: TableCellProps) => (
  <Cell {...props}>
    <Whisper placement="top" speaker={speakerAvisoGarantia} trigger="hover">
      <span>{rowData[dataKey]}</span>
    </Whisper>
  </Cell>
);

const CellStockGarantia12Meses = ({
  rowData,
  dataKey,
  ...props
}: TableCellProps) => {
  const garantias = rowData[dataKey] as Garantia[];
  const [garantiaStr] = garantiaToArrayFormated(garantias);

  return <Cell {...props}>{garantiaStr}</Cell>;
};

const CellStockGarantia24Meses = ({
  rowData,
  dataKey,
  ...props
}: TableCellProps) => {
  const garantias = rowData[dataKey] as Garantia[];
  const [_, garantiaStr] = garantiaToArrayFormated(garantias);

  return <Cell {...props}>{garantiaStr}</Cell>;
};

const Product: React.FC = () => {
  const formRef = useRef<FormProps>(null);
  const [formData, setFormData] = useState<Config>(null);
  const history = useHistory();

  const [busca, setBusca] = useState("");
  const [lastSearch, setLastSearch] = useState("");
  const dispatch = useDispatch();
  const { loading, list } = useSelector((state: State) => state?.products);
  const { list: bag } = useSelector((state: State) => state?.bag);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [showProduct, setShowProduct] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showBag, setShowBag] = useState(false);
  const [isNewSimulate, setIsNewSimulate] = useState(true);
  const [product, setProduct] = useState<SimpleProduct>(null);
  const { user, setConfig } = useAuth();
  const { config } = user;
  const { store } = config || {};

  const tooltipBag = <Tooltip>Ver carrinho</Tooltip>;
  const tooltipClearBag = <Tooltip>Limpar Carrinho</Tooltip>;

  const inputSearch = useRef(null);

  const toogleShowModal = useCallback(() => {
    setShowModal((old) => !old);
  }, [showModal]);

  const cancelModal = useCallback(() => history.push("/admin"), []);

  const handleChange = useCallback((data: Config) => {
    setFormData(data);
  }, []);

  const handleSubmitConfig = useCallback(() => {
    if (formRef.current.check()) {
      dispatch(Actions.handleUpdateUserConfig(formData));
      setConfig(formData);
      openNotification(
        "success",
        "Ajustes",
        "Loja atual atualizada com sucesso!",
        1200
      );
    }
  }, [formData]);

  const handleSearchChange = ({
    currentTarget,
  }: FormEvent<HTMLInputElement>) => {
    setBusca(currentTarget.value);
    setPage(1);
  };

  const bagLength = useCallback(() => bag.length, [bag]);

  const handleIsNewSimulate = useCallback(
    (is) => {
      setIsNewSimulate(is);
    },
    [isNewSimulate]
  );

  const searchProduct = useCallback(() => {
    dispatch(Actions.closeNavbar());
    const value = inputSearch.current.value;

    if (value.trim() !== "") {
      setLastSearch(value);
      setBusca("");

      const id = isNumber(value) ? parseInt(value) : 0;
      const search = !id ? value.trim() : "";

      dispatch(Actions.handleFetchProducts({ id, search, limit, page }));
      setOffset(1700);
      setPage((page) => page + 1);
    }
  }, [busca, limit, page]);

  const handlePaginate = useCallback(
    (offsetY) => {
      if (
        offsetY <= (offset - 50) * -1 &&
        list?.length === limit * (page - 1)
      ) {
        const byId = !!parseInt(lastSearch);
        const id = byId ? parseInt(lastSearch) : 0;
        const search = !byId ? lastSearch : "";

        dispatch(
          Actions.handleFetchPaginationProducts({
            id,
            search,
            limit,
            page: page + 1,
          })
        );
        setPage((old) => old + 1);
      }
    },
    [lastSearch, limit, page, offset, list]
  );

  const openNotification = (funcName, title, description, duration = 800) => {
    Notification[funcName]({
      duration,
      title: title,
      description: <p>{description}</p>,
    });
  };

  const handleAddBag = useCallback(
    (product: SimpleProduct, qtd = 1, stock: Stock, discount = 0) => {
      if (product?.total_disponivel_loja > 0) {
        dispatch(Actions.addProduct(product, qtd, stock, discount));
        openNotification(
          "success",
          "Carrinho",
          "Produto adcionado com sucesso!"
        );
      } else {
        openNotification(
          "warning",
          "Aviso!",
          "O Produto não tem estoque!",
          2000
        );
      }
    },
    []
  );

  const handleViewProduct = (product: SimpleProduct) => {
    setProduct(product);
    setShowProduct(true);
  };

  const toggleViewProduct = () => {
    setShowProduct((old) => !old);
  };

  const toggleViewBag = () => {
    setShowBag((old) => !old);
  };

  const handleClearBag = () => {
    dispatch(Actions.clearBag());
    handleIsNewSimulate(true);
    setShowBag(false);
  };

  useEffect(() => {
    dispatch(Actions.closeNavbar());
    inputSearch.current.focus();

    const pressEnter = inputSearch.current.addEventListener(
      "keyup",
      function (event) {
        event.preventDefault();
        if (event.keyCode === 13) {
          // Enter -> fazer a busca
          searchProduct();
        }
      }
    );

    return () => {
      inputSearch.current.removeEventListener("keyup", pressEnter);
    };
  }, []);

  useEffect(() => {
    if (list?.length) {
      setOffset(list?.length * 45);
    }
  }, [list]);

  const renderHeaderTable = () => (
    <div className="search-table">
      <div className="search-tools">
        <h2>Consultar Produtos</h2>
        <div>
          <Badge content={bagLength() === 0 ? "" : bagLength()}>
            <Whisper placement="top" trigger="hover" speaker={tooltipBag}>
              <IconButton
                onClick={toggleViewBag}
                abIndex={0}
                appearance="primary"
                icon={<Icon icon="shopping-cart" />}
                placement="right"
              >
                Carrinho
              </IconButton>
            </Whisper>
          </Badge>
          <Whisper placement="top" trigger="hover" speaker={tooltipClearBag}>
            <IconButton
              style={{ marginLeft: 15 }}
              onClick={handleClearBag}
              abIndex={0}
              appearance="default"
              icon={<Icon icon="refresh" />}
              placement="right"
            >
              Limpar
            </IconButton>
          </Whisper>
        </div>
      </div>
      <div className="form-group-search">
        <input
          ref={inputSearch}
          placeholder="Busque por produtos aqui!"
          type="text"
          className="rs-input rs-input-group-lg"
          onChange={handleSearchChange}
          value={busca}
          tabIndex={0}
        />
        <IconButton
          tabIndex={0}
          size="lg"
          icon={<Icon icon="search" />}
          onClick={searchProduct}
        >
          Buscar
        </IconButton>
      </div>
      <div className="last-search">
        <p>
          Última consulta: <strong>{lastSearch}</strong>
        </p>
      </div>
    </div>
  );

  return (
    <Panel header={renderHeaderTable()} className="panel-product">
      <Table
        data={list}
        loading={loading}
        onScroll={(x, y) => handlePaginate(y)}
        onRowClick={(rowData) => handleViewProduct(rowData)}
      >
        <Column width={100}>
          <HeaderCell>Código</HeaderCell>
          <CellId dataKey="codigo_produto" />
        </Column>
        <Column width={350} resizable>
          <HeaderCell>Nome</HeaderCell>
          <Cell dataKey="descricao" />
        </Column>
        <Column width={100}>
          <HeaderCell>Preço</HeaderCell>
          <PriceCell dataKey="preco" />
        </Column>
        <Column width={120}>
          <HeaderCell>Estoque total</HeaderCell>
          <CellStock dataKey="total_disponivel_loja" />
        </Column>
        <Column width={200}>
          <HeaderCell>Garantia do fabricante</HeaderCell>
          <CellStockGarantiaFabricante dataKey="garantia_fabricante" />
        </Column>
        <Column width={220} resizable>
          <HeaderCell>Garantia estendida 12 meses</HeaderCell>
          <CellStockGarantia12Meses dataKey="garantia_extendida" />
        </Column>
        <Column width={220} resizable>
          <HeaderCell>Garantia estendida 24 meses </HeaderCell>
          <CellStockGarantia24Meses dataKey="garantia_extendida" />
        </Column>
      </Table>
      <DrawerBag
        show={showBag}
        handleClose={toggleViewBag}
        handleClearBag={handleClearBag}
        isNewSimulate={isNewSimulate}
        handleIsNewSimulate={handleIsNewSimulate}
      />

      <DrawerView
        product={product}
        show={showProduct}
        handleClose={toggleViewProduct}
        handleAddBag={handleAddBag}
        userStore={store}
      />
      <Modal backdrop="static" show={!store} onHide={toogleShowModal} size="xs">
        <Modal.Body>
          <p style={{ textAlign: "center" }}>
            <Icon
              icon="remind"
              style={{
                color: "#ffb300",
                fontSize: 48,
              }}
            />
          </p>
          <p style={{ fontWeight: "bold", fontSize: 18 }}>
            Por favor informe a loja que você trabalha.
          </p>
          <p>
            <i>Você poderá mudar quando quiser em Ajustes.</i>
          </p>
          <Divider />
          <Form
            fluid
            onChange={handleChange}
            model={SchemaForm}
            ref={formRef}
            formValue={formData}
            formDefaultValue={{ store }}
            autocomplete="off"
          >
            <FormGroup>
              <InputPickerStore all name="store" />
              <HelpBlock>Sua Loja atual</HelpBlock>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmitConfig} appearance="primary">
            Salvar
          </Button>
          <Button onClick={cancelModal} appearance="subtle">
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </Panel>
  );
};

export default Product;
