import {
  ROMANEIO_LOAD,
  ROMANEIO_FETCH,
  ROMANEIO_SAVE,
  ROMANEIO_REMOVE
} from '../../constants';

import { ActionTypes, Paginate, Romaneio, RomaneioData, RomaneioParams } from '../../types';

type State = {
  loading: boolean;
  paginate: Paginate<Romaneio>;
};

const defaultState: State = {
  loading: true,
  paginate: {
    data: []
  } as Paginate<Romaneio>,
};

const reducerRomaneio = (state = defaultState, action: ActionTypes) => {
  return (
    {
      [ROMANEIO_LOAD]: {
        ...state,
        loading: action.payload,
      },
      [ROMANEIO_FETCH]: {
        ...state,
        loading: false,
        paginate: action.payload as Paginate<Romaneio>
      },
      [ROMANEIO_SAVE]: {
        ...state,
        loading: false,
        paginate: {
          ...state.paginate,
          data: [action.payload, ...state.paginate.data]
        }
      },
      [ROMANEIO_REMOVE]:{
        ...state,
        loading: false,
        paginate: {
          ...state.paginate,
          data: [...state.paginate.data.filter(r => r.id !== action.payload)]
        }
      },
    }[action.type] || state
  );
};

export default reducerRomaneio;
