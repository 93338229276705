import api from './api.service';
import { Role, RolePermition } from '../types';

const all = async (): Promise<Role[]> => {
  const { data } = await api.get<Role[]>(`/roles`);
  
  return Promise.resolve(data);
};

const rolePermitions = async (role: number): Promise<RolePermition[]> => {
  const { data } = await api.get<RolePermition[]>(`/roles/${role}/permitions`);

  return Promise.resolve(data);
};

const saveRolePermitions = async (role: number, permitions: number[]): Promise<void> => {
  await api.post(`/roles`, {role, permitions});
};


export default {
  all,
  rolePermitions,
  saveRolePermitions
}
