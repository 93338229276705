import React from 'react';
import { Container, Panel } from 'rsuite';
import {useAuth} from '../hooks/auth';
import { Link } from 'react-router-dom';

import logoFeirao from '../assets/logo_feirao_dos_moveis.svg';

const Home: React.FC = () => {
  const {user, signed } = useAuth();

return (<Container className="container-login">
  <Panel header={null}>
  <div className="login-box">
    <img src={logoFeirao} alt="logo do feirão dos móveis" />
    {!signed && (<p>Faça seu login <Link to="/login">aqui!</Link></p>)}
    {signed && (
      <>
        <p style={{textAlign: "center"}}>Bem-vindo ao Feirão dos Móveis<br /><strong>{user.name}</strong></p>
        <p><Link to="/admin">Ir para o Admin</Link></p>
      </>
    )}
  </div>

  </Panel>
</Container>)
;
};

export default Home;
