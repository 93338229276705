import React, { useEffect, MouseEvent, useState, useCallback } from 'react';
import {
  Container,
  Content,
  Sidebar,
  Sidenav,
  Nav,
  Navbar,
  Icon,
  DOMHelper,
  Dropdown
} from 'rsuite';
import { useDispatch, useSelector } from "../../hooks/store";
import classNames from 'classnames';
import { useAuth } from '../../hooks/auth';
import { usePermition } from '../../hooks/permitions';
import UserPanel from '../UserPainel';
import Actions from "../../store/actions";

import '../../styles/admin.css';

import CustomNavLink from '../../components/CustomNavLink';
import { State } from '../../types';

const { getWidth, on } = DOMHelper;

type Props = {
  children: React.ReactNode;
};

const AdminContainer: React.FC<Props> = ({ children }) => {
  const [width, setWidth] = useState(() => getWidth(window));
  const { signOut, user } = useAuth();
  const { expand } = useSelector((state: State) => state?.navbar);
  const dispatch = useDispatch();
  const { Can } = usePermition();

  const handleToggleExpand = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(Actions.toggleNavbar());
  }, []);

  const containerClasses = classNames('page-container', {
    'container-full': !expand,
  });

  useEffect(() => {
    dispatch(Actions.setNavbar(getWidth(window) > 968));

    const widthListener = on(window, 'resize', () => {
      setWidth((old) => getWidth(window));
    });

    return () => {
      widthListener.off();
    };
  }, []);

  useEffect(() => {
    if (width < 968) {
      dispatch(Actions.closeNavbar());
    }
  }, [width]);

  return (
    <Container>
      <Sidebar
        width={expand ? 260 : 56}
        className={!expand ? 'collapsibled' : ''}
        collapsible
      >
        <Sidenav>
          <Sidenav.Header>
            {expand ? (
              <div className="header-logo">Feirão dos Móveis</div>
            ) : (
              <div className="header-logo">FM</div>
            )}
          </Sidenav.Header>
          <Sidenav.Body>
            <Nav>
              <UserPanel username={user?.name} />
              <CustomNavLink
                href="/admin"
                exact
                icon={<Icon icon="dashboard" />}
              >
                Dashboard
              </CustomNavLink>
              <CustomNavLink
                href="/admin/reports"
                exact
                icon={<Icon icon="bar-chart" />}
              >
                Relatórios
              </CustomNavLink>
              <CustomNavLink
                href="/admin/products"
                exact
                icon={<Icon icon="tags" />}
              >
                Consultar Produtos
              </CustomNavLink>
              {Can(["ADMIN_FULL_ACCESS", "FS_ACCESS"]) && (
                <Dropdown title="FS" icon={<Icon icon="truck" />}>
                  <CustomNavLink
                    href="/admin/romaneios"
                    exact
                    icon={<Icon icon="task" />}
                  >
                      Romaneio
                  </CustomNavLink>
                </Dropdown>
              )}
              {Can(["ADMIN_FULL_ACCESS"]) && (
                <Dropdown title="Configurações" icon={<Icon icon="setting" />}>
                  <CustomNavLink
                    href="/admin/acl/permitions"
                    exact
                    icon={<Icon icon="universal-access" />}
                  >
                      Permissões
                  </CustomNavLink>
                  <CustomNavLink
                    href="/admin/acl"
                    exact
                    icon={<Icon icon="people-group" />}
                  >
                    Cargos
                  </CustomNavLink>
                  <CustomNavLink
                    href="/admin/tax"
                    exact
                    icon={<Icon icon="sales" />}
                  >
                    Fator calculo
                  </CustomNavLink>
                </Dropdown>
              )}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </Sidebar>

      <Container>
        <Navbar>
          <Navbar.Header>
            <Nav>
              <Nav.Item
                onClick={handleToggleExpand}
                style={{
                  width: 56,
                  textAlign: 'center',
                }}
              >
                <Icon icon={expand ? 'angle-left' : 'angle-right'} />
              </Nav.Item>
            </Nav>
          </Navbar.Header>
          <Navbar.Body>
            <Nav pullRight>
              <Nav.Item
                onClick={() => signOut()}
                icon={<Icon icon="sign-out" />}
              >
                Sair
              </Nav.Item>
            </Nav>
          </Navbar.Body>
        </Navbar>
        <Content className={containerClasses}>{children}</Content>
      </Container>
    </Container>
  );
};

export default AdminContainer;
