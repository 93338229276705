import {
  REPORT_LOAD,
  REPORT_FETCH,
  REPORT_CREATE,
  REPORT_UPDATE,
  REPORT_DELETE,
  REPORT_LIST,
  REPORT_SEARCH,
} from '../../constants';

import { ActionTypes, ReportState } from '../../types';

type State = {
  loading: boolean;
  list: ReportState[];
};

const defaultState: State = {
  loading: true,
  list: [],
};

const reducerReport = (state = defaultState, action: ActionTypes) => {
  return (
    {
      [REPORT_LOAD]: {
        ...state,
        loading: action.payload,
      },
      [REPORT_SEARCH]: {
        ...state,
        loading: false,
        list: action.payload,
      },
      [REPORT_FETCH]: {
        ...state,
        loading: false,
        list: action.payload,
      },
      [REPORT_LIST]: [...state.list],
      [REPORT_CREATE]: {
        ...state,
        loading: false,
        list: [...state.list, action.payload],
      },
      [REPORT_UPDATE]: {
        ...state,
        loading: false,
        list: [
          ...state.list.filter(
            (report: ReportState) => report?.id !== action.payload?.id,
          ),
          action.payload,
        ],
      },
      [REPORT_DELETE]: {
        ...state,
        loading: false,
        list: [
          ...state.list.filter(
            (report: ReportState) => report?.id !== action.payload,
          ),
        ],
      },
    }[action.type] || state
  );
};

export default reducerReport;
