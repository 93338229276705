import React from 'react';
import {Panel, Row, Col, Icon} from 'rsuite';
import { useAuth } from '../hooks/auth';
import {useHistory} from "react-router-dom"

const Card = ({children, ...rest}) => (
  <Panel {...rest} bordered>
    {children}
  </Panel>
);

const HomeAdmin: React.FC = () => {
  const {user} = useAuth();
  const history = useHistory();

  const renderHeader = () => (
    <div>
      <h2>Feirão dos Móveis Magazine</h2>
      <p>Olá {user.name}</p>
    </div>
  );

  return (
    <Panel header={renderHeader()}>
     <Row>
      <Col md={8} sm={24}>
        <Card className="card" onClick={() => history.push('/admin/reports')}>
          <Icon icon='bar-chart' size="3x" />
          <h3>Relatórios</h3>
        </Card>
      </Col>
      <Col md={8} sm={24}>
        <Card className="card" onClick={() => history.push('/admin/products')}> 
          <Icon icon='tags' size="3x" />
          <h3>Consultar Produtos</h3>
        </Card>
      </Col>
    </Row>
    </Panel>
  );
};

export default HomeAdmin;
