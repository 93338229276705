import { Dispatch } from 'redux';
import reportServiceProvider from '../../services/reports.services';
import { normalizeReport, normalizeReports } from '../../utils';

import * as C from '../../constants';
import { ReportState, Report } from '../../types';

export const loadReports = (active = true) => ({
  type: C.REPORT_LOAD,
  payload: active,
});

export const fetchReports = (reports: ReportState[]) => ({
  type: C.REPORT_FETCH,
  payload: reports,
});

export const receiveReports = () => ({
  type: C.REPORT_LIST,
});

export const updateReport = (report: ReportState) => ({
  type: C.REPORT_UPDATE,
  payload: report,
});

export const createReport = (report: ReportState) => ({
  type: C.REPORT_CREATE,
  payload: report,
});

export const deleteReport = (id) => ({
  type: C.REPORT_DELETE,
  payload: id,
});

export const searchReports = (reports: ReportState[]) => ({
  type: C.REPORT_SEARCH,
  payload: reports,
});

export const handleFetchReports = () => async (dispatch: Dispatch) => {
  try {
    dispatch(loadReports());
    const reports = await reportServiceProvider.all();
    const normalize = normalizeReports(reports);
    console.log("handleFetchReports", normalize)

    dispatch(fetchReports(normalize));
  } catch (error) {
    console.error(error);
  }
};

export const handleSearchReports = (search: string = '') => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadReports());
    const reports = await reportServiceProvider.all(search);
    const normalize = normalizeReports(reports);
    console.log("handleSearchReports", normalize)

    dispatch(searchReports(normalize));
  } catch (error) {
    dispatch(loadReports(false));
    console.error(error);
  }
};

export const handleUpdateReport = (reportData: ReportState) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadReports());
    const report: Report = {
      ...reportData,
      active: !!reportData.active,
      fields: reportData.fields?.join(),
      roles: reportData.roles?.join(),
    };

    console.log("Report send: ", report)
    const newReport = await reportServiceProvider.update(report);
    const normalize = normalizeReport(newReport);

    dispatch(updateReport(normalize));
  } catch (error) {
    dispatch(loadReports(false));
    console.error(error);
  }
};

export const handleCreateReport = (reportData: ReportState) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadReports());
    const report: Report = {
      ...reportData,
      active: !!reportData.active,
      fields: reportData.fields?.join(),
      roles: reportData.roles?.join(),
    };

    const newReport = await reportServiceProvider.create(report);
    const normalize = normalizeReport(newReport);

    dispatch(createReport(normalize));
  } catch (error) {
    dispatch(loadReports(false));
    console.error(error);
  }
};

export const handleDeleteReport = (reportData: ReportState) => async (
  dispatch: Dispatch,
) => {
  try {
    console.log(reportData);
    dispatch(loadReports());
    await reportServiceProvider.del(reportData);
    dispatch(deleteReport(reportData?.id));
  } catch (error) {
    dispatch(loadReports(false));
    console.error(error);
  }
};

export default {
  loadReports,
  fetchReports,
  createReport,
  updateReport,
  deleteReport,
  receiveReports,
  handleFetchReports,
  handleSearchReports,
  handleCreateReport,
  handleUpdateReport,
  handleDeleteReport,
};
