import {
  Report,
  ReportState,
  InputPickerData,
  InterestTax,
  ItemBag,
  Role,
  RoleTagPicker,
  Garantia,
} from "../types";
import { Notification } from "rsuite";

export const dataFilter = [
  { label: "Lojas", value: "LJ" },
  { label: "Data início", value: "DI" },
  { label: "Data fim", value: "DF" },
  { label: "Vendedor", value: "VD" },
  { label: "Comissão a receber", value: "AR" },
  { label: "Comissão a vista", value: "AV" },
  { label: "Comissão entrata", value: "EN" },
  { label: "Filtrar por saldo", value: "FILT" },
];

export const formasPagamento = [
  { label: "À vista", value: 1 },
  { label: "Crediário", value: 2 },
  { label: "Cartão de Crédito", value: 3 },
];

export const stores_para_tocantins = [
  8, 9, 11, 15, 19, 20, 21, 22, 33, 36, 39, 40, 49, 51, 55, 56, 57, 58, 59, 61,
  62, 63, 64, 66, 67, 68,
];

export const garantiaToString = (garantias: Garantia[]) =>
  garantias?.reduce(
    (acc, garantia) => {
      if (garantia?.garantia && garantia?.preco) {
        return acc.concat(
          acc !== "" ? " - " : "",
          `${garantia?.garantia}, R$ ${parseFloat(
            garantia?.preco !== null ? garantia?.preco.toString() : "0"
          ).toFixed(2)}`
        );
      }

      return acc;
    },

    ""
  );

export const garantiaToArrayFormated = (garantias: Garantia[]) =>
  garantias?.reduce((acc, garantia) => {
    if (garantia?.garantia && garantia?.preco) {
      return [
        ...acc,
        `${garantia?.garantia}, R$ ${parseFloat(
          garantia?.preco !== null ? garantia?.preco.toString() : "0"
        ).toFixed(2)}`,
      ];
    }

    return acc;
  }, []);

export const roleTransformeTagPicker = (roles: Role[]): RoleTagPicker[] => {
  const transformed = roles.map((role) => ({
    label: role.name,
    value: `${role.id}`,
  }));

  return transformed;
};

export const normalizeReport = (report: Report): ReportState => ({
  ...report,
  fields: report.fields?.replace(/ /gi, "").split(","),
  roles: report.roles?.replace(/ /gi, "").split(","),
});

export const normalizeReports = (data: Report[]): ReportState[] =>
  data.map((rep) => normalizeReport(rep));

type Store = {
  id: number;
  name: string;
};

type Salespeople = Store;

export const normalizeStores = (stores: Store[]): InputPickerData[] => {
  const normalized = stores.map((store) => ({
    label: store.name,
    value: store.id,
  }));

  normalized.unshift({
    label: "TODAS AS LOJAS",
    value: 0,
  });

  return normalized;
};

export const normalizeSalespeople = (
  salespeople: Salespeople[]
): InputPickerData[] => {
  const normalized = salespeople.map((sp) => ({
    label: sp.name,
    value: sp.id,
  }));

  return normalized;
};

export const sortedInterestTax = (
  interestTax: InterestTax[]
): InterestTax[] => {
  if (interestTax) {
    return interestTax.sort((a, b) => {
      if (a.quantity === b.quantity) return 0;
      if (a.quantity > b.quantity) return 1;
      if (a.quantity < b.quantity) return -1;
    });
  }
};

export const calcPrice = (itemBag: ItemBag) => {
  if (!itemBag) {
    return 0;
  }

  const { item, qtd, discount } = itemBag;
  const value = item?.preco * qtd;

  if (discount && discount !== 0) {
    const disc = (discount * value) / 100;
    const valueWithDescount = value - disc;

    return valueWithDescount;
  }

  return value;
};

export const calcPerc = (itemBag: ItemBag) => {
  const { item, qtd, total } = itemBag;
  const value = item.preco * qtd;

  const perc = 100 - (total * 100) / value;

  return perc;
};

export const calcTotalFinal = (list: ItemBag[]) => {
  const total = list.reduce<number>((calculator, item) => {
    const value = calcPrice(item);

    return calculator + value;
  }, 0);

  return total;
};

export const calcTotalBag = (list: ItemBag[]) => {
  const total = list.reduce<number>((calculator, itemBag) => {
    return calculator + itemBag.item?.preco * itemBag.qtd;
  }, 0);

  return total;
};

export const calcTotalDiscount = (total, discount) => {
  const discountTotal = (discount * 100) / total;

  return discountTotal;
};

export const reduceUpdateList = (items: ItemBag[]) => {
  const list: ItemBag[] = [
    ...items.map((ib: ItemBag) => ({
      ...ib,
      discount: calcPerc(ib),
    })),
  ];

  const total_final = calcTotalFinal(list),
    total_value = calcTotalBag(list),
    total_discount_value = total_value - total_final,
    discount = calcTotalDiscount(total_value, total_discount_value);

  const bag = {
    list,
    discount: discount.toFixed(2),
    total_final: total_final.toFixed(2),
    total_value: total_value.toFixed(2),
    total_discount_value: total_discount_value.toFixed(2),
  };

  return bag;
};

export const reduceAddItem = (items: ItemBag[], itemBag: ItemBag) => {
  const list: ItemBag[] = [
    ...items.filter(
      (ib: ItemBag) =>
        ib?.item?.codigo_produto !== itemBag?.item?.codigo_produto
    ),
    {
      ...itemBag,
      total: calcPrice(itemBag),
    },
  ];

  const total_final = calcTotalFinal(list),
    total_value = calcTotalBag(list),
    total_discount_value = total_value - total_final,
    discount = calcTotalDiscount(total_value, total_discount_value);

  const bag = {
    list,
    discount: discount.toFixed(2),
    total_final: total_final.toFixed(2),
    total_value: total_value.toFixed(2),
    total_discount_value: total_discount_value.toFixed(2),
  };

  return bag;
};

export const reduceChangeItem = (items: ItemBag[], itemBag: ItemBag) => {
  const list: ItemBag[] = [
    ...items.map((ib: ItemBag) => {
      if (ib?.item?.codigo_produto === itemBag?.item?.codigo_produto) {
        return {
          ...itemBag,
          total: calcPrice(itemBag),
        };
      }

      return ib;
    }),
  ];

  const total_final = calcTotalFinal(list),
    total_value = calcTotalBag(list),
    total_discount_value = total_value - total_final,
    discount = calcTotalDiscount(total_value, total_discount_value);

  const bag = {
    list,
    discount: discount.toFixed(2),
    total_final: total_final.toFixed(2),
    total_value: total_value.toFixed(2),
    total_discount_value: total_discount_value.toFixed(2),
  };

  return bag;
};

export const reduceChangePriceItem = (items: ItemBag[], itemBag: ItemBag) => {
  const list: ItemBag[] = [
    ...items.map((ib: ItemBag) => {
      if (ib?.item?.codigo_produto === itemBag?.item?.codigo_produto) {
        return {
          ...itemBag,
          discount: calcPerc(itemBag),
        };
      }

      return ib;
    }),
  ];

  const total_final = calcTotalFinal(list),
    total_value = calcTotalBag(list),
    total_discount_value = total_value - total_final,
    discount = calcTotalDiscount(total_value, total_discount_value);

  const bag = {
    list,
    discount: discount.toFixed(2),
    total_final: total_final.toFixed(2),
    total_value: total_value.toFixed(2),
    total_discount_value: total_discount_value.toFixed(2),
  };

  return bag;
};

export const reduceChangeDiscountGeral = (items: ItemBag[], discountGeral) => {
  const list: ItemBag[] = [
    ...items.map((ib: ItemBag) => {
      return {
        ...ib,
        discount: discountGeral,
        total: calcPrice({ ...ib, discount: discountGeral }),
      };
    }),
  ];

  const total_final = calcTotalFinal(list),
    total_value = calcTotalBag(list),
    total_discount_value = total_value - total_final,
    discount = calcTotalDiscount(total_value, total_discount_value);

  const bag = {
    list,
    discount: discount.toFixed(2),
    total_final: total_final.toFixed(2),
    total_value: total_value.toFixed(2),
    total_discount_value: total_discount_value.toFixed(2),
  };

  return bag;
};

export const isNumber = (value) => {
  const reg = /^[0-9]*$/gm;
  const text = String(value).trim();
  const result = text.match(reg);

  return result !== null;
};

type NotifyType = "open" | "info" | "success" | "warning" | "error";

export const openNotification = (
  funcName: NotifyType,
  title,
  description,
  duration = 800
) => {
  Notification[funcName]({
    duration,
    title: title,
    description: description,
  });
};

export default {
  dataFilter,
  normalizeReport,
  normalizeReports,
  normalizeStores,
  normalizeSalespeople,
  sortedInterestTax,
  calcPrice,
  calcPerc,
  calcTotalFinal,
  reduceUpdateList,
  reduceAddItem,
  reduceChangeItem,
  reduceChangeDiscountGeral,
  reduceChangePriceItem,
  calcTotalBag,
  calcTotalDiscount,
  isNumber,
  roleTransformeTagPicker,
};
