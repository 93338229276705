import React, { useEffect, useState, useCallback } from "react";
import { 
  Panel,
  List,
  FlexboxGrid,
  Tooltip,
  Loader,
  Whisper,
  IconButton,
  Icon,
  CheckboxGroup,
  Checkbox,
} from "rsuite";
import {useHistory, useParams} from "react-router-dom"
import { useDispatch, useSelector } from "../hooks/store";
import Actions from "../store/actions";
import rolePermitionsServiceProvider from '../services/roles.services';

import { State, Role } from "../types";

type Params = {
  id: string;
}

const styleItem = {
  padding: 10,
  fontSize: "1rem",
  fontWeight: 600
}

const styleItemDescription = {
  margin: 0,
  fontSize: "0.9rem",
  fontWeight: 500
}

const styleLoad = {
  padding: 10,
  margin: 15
}

const tooltipGoBack = <Tooltip>Retornar para todos os cargos</Tooltip>;
const tooltipSave = <Tooltip>Salvar permissões relacionadas ao cargo</Tooltip>;

const RolePermitions = () => {
  const permitons = useSelector((state: State) => state?.permitions);
  const [value, setValue] = useState([]);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory<Role>();
  const { id } = useParams<Params>();
  const { location : { state }} = history;

  useEffect(() => {
    dispatch(Actions.handleReceivePermitions());

    const getRelations = async () => {
      const result = await rolePermitionsServiceProvider.rolePermitions(Number(id));
      const permitions = result.map(rp => rp.permition.id);
      setValue(permitions);
    }

    getRelations();
    
  },[]);

  const handleSavePermitions = useCallback(
    async () => {
      setLoad(true);
      await rolePermitionsServiceProvider.saveRolePermitions(Number(id), value);
      setLoad(false);
    },
    [value, load]);

  const toggleValue = useCallback(
  (id) => {
    const exist = value.find(e => e === id);

    if(exist) {
      setValue(old => old.filter(v => v !== id));
    }else {
      setValue(old => [...old, id]);
    }
    },
  [value, permitons])
 
 
  const renderHeader = () => (
    <div className="search-table">
      <div className="search-tools">
        <div>
          <h2>Definir permissões </h2>
          <p><strong>Cargo: </strong>{state?.name}</p>
        </div>
        <div>
          <Whisper placement="top" trigger="hover" speaker={tooltipGoBack}>
            <IconButton 
              onClick={() => history.goBack()} 
              abIndex={0} 
              appearance="default" 
              icon={<Icon icon="back-arrow" />} 
              placement="left"
            >
              Voltar para Cargos
            </IconButton>
          </Whisper>
          <Whisper placement="top" trigger="hover" speaker={tooltipSave}>
            <IconButton 
              onClick={handleSavePermitions} 
              abIndex={0} 
              appearance="primary" 
              icon={<Icon icon="save" />} 
              placement="right"
              style={{marginLeft: 10}}
              disabled={load}
            >
              Salvar permissões
            </IconButton>
          </Whisper>
        </div>
      </div>
    </div>
  );

  return (
    <Panel header={renderHeader()}>
      {permitons?.loading && (
      <div style={styleLoad}>
        <Loader size="md" content="Carregando permissões..." />
      </div>
    )}

    {load && (
      <div style={styleLoad}>
        <Loader size="md" content="Atualizando permissões..." />
      </div>
    )}

      <CheckboxGroup
        inline
        name="checkboxList"
        value={value}
      >
        <List hover>
          {permitons?.list.map((permition, index) => (
            <List.Item 
              key={`${permition.id}-${permition.name}`}  
              index={index}
              onClick={() => toggleValue(permition.id)}
            >
              <FlexboxGrid align="middle">
                <FlexboxGrid.Item colspan={1}>
                  <Checkbox value={permition.id}/>
                </FlexboxGrid.Item>            
                <FlexboxGrid.Item colspan={23} style={styleItem}>
                  <p><strong>{permition.name}</strong></p>
                  <p style={styleItemDescription}>{permition.description}</p>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
          ))}
        </List>
      </CheckboxGroup>
    </Panel>
  );
};

export default RolePermitions;
