import React  from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import AdminContainer from '../components/AdminContainer';
import Role from '../pages/Role';
import Permition from '../pages/Permition';
import RolePermitions from '../pages/RolePermitions';
import NoMatch from '../pages/NoMatch';

const ACL: React.FC = () => {
  const { path } = useRouteMatch();

  return (
      <Switch>
        <Route path={path} exact>
          <AdminContainer children={<Role />} />
        </Route>
        <Route path={`${path}/roles/:id`}>
          <AdminContainer children={<RolePermitions />} />
        </Route>
        <Route path={`${path}/permitions`}>
          <AdminContainer children={<Permition />} />
        </Route>
        <Route path={`${path}/*`}>
          <NoMatch />
        </Route>
      </Switch>
  );
};

export default ACL;