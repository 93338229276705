import api from './api.service';
import { InterestTax } from '../types';

const all = async (): Promise<InterestTax[]> => {
  const { data } = await api.get<InterestTax[]>(`/interesttax`);
  return data;
};

const create = async (interestTax: InterestTax): Promise<InterestTax> => {
  const { data } = await api.post<InterestTax>(`/interesttax`, interestTax);
  return data;
};

const update = async (interestTax: InterestTax): Promise<InterestTax> => {
  const { data } = await api.put<InterestTax>(`/interesttax/${interestTax?.id}`, interestTax);
  return data;
};

const del = async (interestTax: InterestTax): Promise<void> => {
  await api.delete(`/interesttax/${interestTax?.id}`);
};

export default {
  all,
  create,
  update,
  del,
};
