import React from "react";
import {
  Table,
  IconButton,
  Icon,
  TableCellProps,
  Tooltip,
  Whisper,
} from "rsuite";
import { usePermition } from '../../../hooks/permitions';

const { Cell } = Table;

const viewReport = <Tooltip>Vizualizar</Tooltip>;
const editReport = <Tooltip>Editar</Tooltip>;
const excluirReport = <Tooltip>Excluir</Tooltip>;

const ActionCell: React.FC<TableCellProps> = ({
  rowData,
  dataKey,
  openEdit,
  handleDelete,
  openView,
  ...props
}) => {
  const { Can } = usePermition();

  return (
    <Cell {...props} className="link-group">
      {openView && (
        rowData["active"] ? (
          <Whisper placement="top" trigger="hover" speaker={viewReport}>
            <IconButton
              appearance="subtle"
              onClick={() => openView(rowData)}
              icon={<Icon icon="eye" />}
            />
          </Whisper>
        ) : (
          <Whisper placement="top" trigger="hover" speaker={viewReport}>
            <IconButton
                appearance="subtle"
                onClick={() => {}}
                icon={<Icon icon="low-vision" />}
              />
           </Whisper>
        ))}
        {Can(["UPDATE_REPORT", "ADMIN_FULL_ACCESS"]) && (
         <Whisper placement="top" trigger="hover" speaker={editReport}>
          <IconButton
            appearance="subtle"
            onClick={() => openEdit(rowData)}
            icon={<Icon icon="edit2" />}
          />
        </Whisper>
        )}
        {Can(["DELETE_REPORT", "ADMIN_FULL_ACCESS"]) && (
         <Whisper placement="top" trigger="hover" speaker={excluirReport}>
          <IconButton
            appearance="subtle"
            onClick={() => handleDelete(rowData)}
            icon={<Icon icon="trash" />}
          />
        </Whisper>
        )}
    </Cell>
  );
};

export default ActionCell;
