import React, { useEffect, useState, useCallback, useRef } from "react";
import { 
  Panel,
  List,
  FlexboxGrid,
  Tooltip,
  Loader,
  Whisper,
  IconButton,
  Icon,
  Modal,
  Divider,
  Form,
  Schema,
  FormProps,
  Button
} from "rsuite";
import {useHistory} from "react-router-dom"
import { useDispatch, useSelector } from "../hooks/store";
import Actions from "../store/actions";
import TextField from '../components/Form/TextField';

import { State, Permition as TPermition } from "../types";
import slugify from "slugify";

const styleItem = {
  padding: 10,
}

const styleITitle = {
  fontSize: "1rem",
  fontWeight: 600
}

const styleIDescription = {
  fontSize: "0.8rem",
  marginTop: 0,
  color: "#888",
}

const styleLoad = {
  padding: 10,
  margin: 15
}

const styleCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const { NumberType, StringType } = Schema.Types;

const SchemaForm = Schema.Model<TPermition>({
  id: NumberType(),
  name: StringType().isRequired("Informe o nome da permissão."),
  description: StringType().isRequired("Descreva a permissão."),
});


const tooltipPermissions = <Tooltip>Criar um nova permissão de usuário</Tooltip>;
const editPermition = <Tooltip>Editar permissão</Tooltip>;
const excluirPermition = <Tooltip>Excluir permissão</Tooltip>;
const tooltipGoBack = <Tooltip>Voltar para cargos</Tooltip>;

const Permition = () => {
  const permitions = useSelector((state: State) => state?.permitions);
  const [showModal, setShowModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [permition, setPermition] = useState<TPermition>(null);
  const [formData, setFormData] = useState<TPermition>(null);
  const formRef = useRef<FormProps>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(Actions.handleReceivePermitions());
  },[]);

  const handleRemoveConfirmation = (permition: TPermition) => {
    setConfirmation(true);
    setPermition(permition);
  }

  const handleOpenNewPermition = () => {
    setShowModal(true);
  }

  const handleEditPermition = (permition: TPermition) => {
    setFormData(permition)
    setShowModal(true);
  }

  const handleDeletePermition = (permition: TPermition) => {
    dispatch(Actions.handleDeletePermitions(permition));
  }

  const handleRemovePermition = useCallback(() => {
    handleDeletePermition(permition);
    handleCloseModalConfirmation();
  }, [permition]);

  const handleSubmitPermition = () => {
   if(formRef.current.check()){
     const name = slugify(formData.name, "_").toLocaleUpperCase().replace("-", "_");

     const permition = {
      ...formData,
      name,
    }

    if(formData?.id){
      dispatch(Actions.handleUpdatePermition(permition));
    }else{
      dispatch(Actions.handleCreatePermition(permition));
    }

    handleCloseModal()
   }
  }

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setFormData(null);
  }, [showModal]);

  const handleCloseModalConfirmation = useCallback(() => {
    setConfirmation(false);
    setPermition(null);
  }, [confirmation, permition]);  

  const handleChange = useCallback((data: TPermition) => {
    setFormData(data);
  }, []);
 
  const renderHeader = () => (
    <div className="search-table">
      <div className="search-tools">
        <h2>Permissões de usuário</h2>
        <div>
          <Whisper placement="top" trigger="hover" speaker={tooltipGoBack}>
            <IconButton 
              onClick={() => history.goBack()} 
              abIndex={0} 
              appearance="default" 
              icon={<Icon icon="back-arrow" />} 
              placement="left"
            >
              Voltar para Cargos
            </IconButton>
          </Whisper>
          <Whisper placement="top" trigger="hover" speaker={tooltipPermissions}>
            <IconButton 
              onClick={handleOpenNewPermition} 
              abIndex={0} 
              appearance="primary" 
              icon={<Icon icon="ban" />} 
              placement="right"
              style={{marginLeft: 10}}
            >
              Nova Permissão
            </IconButton>
          </Whisper>
        </div>
      </div>
    </div>
  );

  return (
    <Panel header={renderHeader()}>
      {permitions?.loading && (
      <div style={styleLoad}>
        <Loader size="md" content="Carregando permissões..." />
      </div>
    )}

      <List hover>
        {permitions?.list.map((permition, index) => (
          <List.Item 
            key={`${permition.id}-${permition.name}`}  
            index={index} 
          >
            <FlexboxGrid align="middle">
              <FlexboxGrid.Item colspan={1} style={{...styleItem, ...styleCenter}}>
                <Icon icon="expeditedssl"  size="2x"/>
              </FlexboxGrid.Item>            
              <FlexboxGrid.Item colspan={18} style={styleItem}>
                <p style={styleITitle} className="text-up"><strong>{permition.name}</strong></p>
                <p style={styleIDescription}>{permition.description}</p>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={5}>
                <Whisper placement="top" trigger="hover" speaker={editPermition}>
                  <IconButton
                    appearance="subtle"
                    size="lg"
                    onClick={() => handleEditPermition(permition)}
                    icon={<Icon icon="edit2"/>}
                  />
                </Whisper>
                <Whisper placement="top" trigger="hover" speaker={excluirPermition}>
                  <IconButton
                    appearance="subtle"
                    size="lg"
                    onClick={() => handleRemoveConfirmation(permition)}
                    icon={<Icon icon="trash"/>}
                  />
                </Whisper>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </List.Item>
        ))}
      </List>
      {/* Add a new permition */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Body>
            <p style={{fontWeight: "bold", fontSize: 18}}>Criar permissão de usuário</p>
            <p><i>As permissões seram utilizadas para o controle de acesso dos usuários do sistema.</i></p>
            <Divider />
            <Form
              fluid
              onChange={handleChange}
              model={SchemaForm}
              ref={formRef}
              formValue={formData}
              formDefaultValue={{permition}}
              autocomplete="off"
            >
              <div style={{display: "none"}}>
                <TextField name="id"  type="hidden"/>
              </div>
              <TextField name="name" label="Permisssão" required />
              <TextField name="description" label="Descrição" required />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleSubmitPermition} appearance="primary">
              Salvar
            </Button>
            <Button onClick={handleCloseModal} appearance="subtle">
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal backdrop="static" show={confirmation} onHide={handleCloseModalConfirmation} size="xs">
          <Modal.Body>
            <p style={{textAlign: "center"}}>
              <Icon
                icon="remind"
                style={{
                  color: '#ffb300',
                  fontSize: 64
                }}
              />
            </p>
            <p style={{fontWeight: "bold", fontSize: 18}}>Tem certeza que quer remover esta permissão?</p>
            <p><i>Após confirmação não poderá desfazer esta ação.</i></p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleRemovePermition} appearance="primary">
              Sim, pode escluir
            </Button>
            <Button onClick={handleCloseModalConfirmation} appearance="subtle">
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
    </Panel>
  );
};

export default Permition;
