import { isNull } from 'util';
import * as C from '../../constants';
import { ItemBag, Payment, Product, SimpleProduct, Stock } from '../../types';

export const loadBag = (active = true) => ({
  type: C.BAG_LOAD,
  payload: active,
});

export const listBag = () => ({
  type: C.BAG_LIST,
  payload: null,
});

export const clearBag = () => ({
  type: C.BAG_CLEAR,
  payload: {
    list: [],
    discount: 0,
    total_final: 0,
    total_value: 0,
    input_value: 0,
    total_discount_value: 0,
    payment_form: null,
  },
});

export const addProduct = (product: SimpleProduct, qtd:number, stock: Stock, discount: number) => {
  return ({
    type: C.BAG_ADD,
    payload: {qtd, item: product, discount, stock},
  })
};

export const addBagInputValue = (value: number) => {
  return ({
    type: C.BAG_ADD_INPUT_VALUE,
    payload: value,
  })
};

export const addBagDiscountItem = (itemBag: ItemBag) => {
  return ({
    type: C.BAG_ADD_DISCOUNT_ITEM,
    payload: itemBag,
  })
};

export const addBagDiscountGeral = (discount) => {
  console.log("addBagDiscountGeral: ", discount);
  
  return ({
    type: C.BAG_ADD_DISCOUNT_GERAL,
    payload: discount,
  })
};

export const addBagQtdItem = (itemBag: ItemBag) => {
  return ({
    type: C.BAG_ADD_QTD_ITEM,
    payload: itemBag,
  })
};

export const addBagTotalItem = (itemBag: ItemBag) => {
  return ({
    type: C.BAG_ADD_PRICE_ITEM,
    payload: itemBag,
  })
};

export const addBagPaymentForm = (paymentForm: Payment) => {
  return ({
    type: C.BAG_ADD_PAYMENT_FORM,
    payload: paymentForm,
  })
};

export const removeProduct = (product: SimpleProduct) => ({
  type: C.BAG_REMOVE,
  payload: product,
});

export default {
  loadBag,
  listBag,
  addProduct,
  removeProduct,
  clearBag,
  addBagInputValue,
  addBagDiscountItem,
  addBagDiscountGeral,
  addBagQtdItem,
  addBagTotalItem,
  addBagPaymentForm
};
