import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Drawer,
  Button,
  Divider,
  ControlLabel,
  FormGroup,
  Tooltip,
  Whisper,
  Icon,
  InputNumber,
  InputGroup,
  FlexboxGrid,
  List,
  Table,
} from "rsuite";
import { SimpleProduct, Stock } from "../../../types";
import ProdutoServiceProvider from "../../../services/productStock.services";
import { garantiaToString } from "../../../utils";

const { Cell, Column, HeaderCell } = Table;

type Props = {
  product: SimpleProduct;
  show: boolean;
  userStore: number;
  handleClose: () => void;
  handleAddBag: (
    product: SimpleProduct,
    qtd: number,
    stock: Stock,
    discount: number
  ) => void;
};

type SelectableStock = Stock & {
  selected: boolean;
};

const DrawerView = ({
  product,
  show,
  userStore,
  handleClose,
  handleAddBag,
}: Props) => {
  const tooltipAdd = <Tooltip>Adicionar produto no carrinho</Tooltip>;
  const tooltipSair = <Tooltip>Sair (Esc)</Tooltip>;
  const [qtd, setQtd] = useState(1);
  const [stocks, setStocks] = useState<SelectableStock[]>([]);
  const [stock, setStock] = useState<SelectableStock>(null);
  const [loading, setLoading] = useState(true);
  const [hasStore, setHasStore] = useState(false);
  const inputRef = useRef(null);

  const handleMinus = () => {
    inputRef.current.handleMinus();
  };

  const handlePlus = () => {
    inputRef.current.handlePlus();
  };

  const handleAdd = useCallback(() => {
    handleAddBag(product, qtd, stock, 0);
    handleClose();
  }, [product, qtd, stock]);

  const listener = (event) => {
    event.preventDefault();

    if (event.keyCode === 109) {
      handleMinus();
    }

    if (event.keyCode === 107) {
      handlePlus();
    }
  };

  const handleChandeValue = useCallback(
    (value, event) => {
      setQtd(+value);
    },
    [qtd]
  );

  const handleSelectStock = useCallback(
    (data) => {
      setStock(data);
      setStocks((stocks) =>
        stocks.map((s) => {
          if (
            s.loja.toString() === data.loja.toString() &&
            s.grade === data.grade
          ) {
            return {
              ...s,
              selected: true,
            };
          }

          return {
            ...s,
            selected: false,
          };
        })
      );
    },
    [stocks, stock]
  );

  const renderLoading = useCallback(() => {
    return (
      <div className={`loading ${loading ? "" : "loaded"}`}>
        <p>Carregando estoque...</p>
      </div>
    );
  }, [loading]);

  useEffect(() => {
    setStocks([]);
    // buscar estoque do produto
    const getStock = async (id) => {
      if (id) {
        setLoading(true);
        const check = (stock) => stock.loja.toString() === userStore.toString();
        const unCheck = (stock) =>
          stock.loja.toString() !== userStore.toString();

        let productStock = await ProdutoServiceProvider.getStock(id);
        let lists = productStock?.map((stock: Stock) => {
          return {
            ...stock,
            selected: false,
          };
        });

        let myStore = lists?.filter(check);
        let others = lists?.filter(unCheck);

        if (myStore.length > 0) {
          myStore[0].selected = true;
          setStock(myStore[0]);
          setHasStore(true);
          lists = myStore.concat(others);
        } else {
          setStock(null);
          setHasStore(false);
        }

        setStocks(lists);
        setLoading(false);
      }
    };

    if (show) {
      setQtd(1);
      document.addEventListener("keyup", listener);
      getStock(product?.codigo_produto);
    }

    return () => {
      document.removeEventListener("keyup", listener);
    };
  }, [product, show, userStore, hasStore]);

  return (
    <Drawer full show={show} onHide={handleClose}>
      <Drawer.Header>
        <Drawer.Title>
          <h1>Detalhes do Produto</h1>
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <div className="product-view">
          <FlexboxGrid
            justify="space-between"
            style={{ margin: 10, marginBottom: 25 }}
          >
            <FlexboxGrid.Item colspan={11}>
              <Divider>Detalhas</Divider>
              <List bordered style={{ width: "100%" }}>
                <List.Item>
                  <p>
                    <strong style={{ fontSize: "1rem", marginRight: "0.5rem" }}>
                      Código do produto:{" "}
                    </strong>
                    <span style={{ fontSize: "2.5rem", fontWeight: "bold" }}>
                      {product?.codigo_produto}
                    </span>
                  </p>
                </List.Item>
                <List.Item>
                  <p>
                    <strong style={{ fontSize: "1rem", marginRight: "0.5rem" }}>
                      Descrição:{" "}
                    </strong>
                    <span style={{ fontSize: "1rem" }}>
                      {product?.descricao}
                    </span>
                  </p>
                </List.Item>
                <List.Item>
                  <p>
                    <strong style={{ fontSize: "1rem", marginRight: "0.5rem" }}>
                      Valor:{" "}
                    </strong>
                    <span
                      style={{ fontSize: "1rem" }}
                    >{`R$ ${product?.preco.toFixed(2)}`}</span>
                  </p>
                </List.Item>
                <List.Item>
                  <p>
                    <strong style={{ fontSize: "1rem", marginRight: "0.5rem" }}>
                      Total disponível:{" "}
                    </strong>
                    <span style={{ fontSize: "1rem" }}>
                      {product?.total_disponivel_loja}
                    </span>
                  </p>
                </List.Item>
                <List.Item>
                  <p>
                    <strong style={{ fontSize: "1rem", marginRight: "0.5rem" }}>
                      Garantia do fabricante:{" "}
                    </strong>
                    <span style={{ fontSize: "1rem" }}>
                      {product?.garantia_fabricante}
                    </span>
                  </p>
                </List.Item>
                <List.Item>
                  <p>
                    <strong style={{ fontSize: "1rem", marginRight: "0.5rem" }}>
                      Garantia estendida:{" "}
                    </strong>
                    <span style={{ fontSize: "1rem" }}>
                      {garantiaToString(product?.garantia_extendida)}
                    </span>
                  </p>
                </List.Item>
              </List>
              <div style={{ marginTop: "2rem" }}>
                <p
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    color: "#ff0015",
                    marginBottom: ".5rem",
                  }}
                >
                  AVISO!
                </p>
                <div
                  style={{
                    marginTop: ".5rem",
                    fontSize: "1rem",
                    border: "1px solid #ffc800",
                    backgroundColor: "#fcefbb",
                    padding: "0.3rem",
                  }}
                >
                  <span>
                    Importante confirmar no produto o prazo da{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      garantia do fabricante
                    </span>{" "}
                    e em caso de divergência informar o{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Suporte
                    </span>
                    .
                  </span>
                </div>
              </div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={11}>
              <Divider>Estoque por loja</Divider>
              {!hasStore && !loading && (
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#f44336",
                    fontSize: "1.2rem",
                  }}
                >
                  Sua loja não possui estoque deste produto!
                </p>
              )}
              <Table
                height={450}
                data={stocks || []}
                style={{ cursor: "pointer", fontSize: "1rem" }}
                onRowClick={(rowData) => handleSelectStock(rowData)}
                rowClassName={(rowData) =>
                  rowData?.selected ? "selected" : ""
                }
                loading={loading}
                renderLoading={renderLoading}
              >
                <Column width={80}>
                  <HeaderCell style={{ fontSize: "1rem" }}>Loja</HeaderCell>
                  <Cell dataKey="loja" />
                </Column>
                <Column width={120}>
                  <HeaderCell style={{ fontSize: "1rem" }}>Grade</HeaderCell>
                  <Cell dataKey="grade" />
                </Column>
                <Column width={150}>
                  <HeaderCell style={{ fontSize: "1rem" }}>
                    Estoque disponível
                  </HeaderCell>
                  <Cell dataKey="disponivel_loja" />
                </Column>
              </Table>
              <Divider style={{ marginBottom: 20, marginTop: 20 }} />
              <div style={{ width: 250 }}>
                <FormGroup>
                  <ControlLabel
                    style={{ display: "block", marginBottom: 10, fontSize: 20 }}
                  >
                    Quantidade
                  </ControlLabel>
                  <InputGroup>
                    <InputGroup.Button onClick={handleMinus}>
                      -
                    </InputGroup.Button>
                    <InputNumber
                      className={"custom-input-number"}
                      ref={inputRef}
                      size="lg"
                      max={stock?.disponivel_loja}
                      min={0}
                      value={qtd}
                      onChange={handleChandeValue}
                    />
                    <InputGroup.Button onClick={handlePlus}>
                      +
                    </InputGroup.Button>
                  </InputGroup>
                </FormGroup>
              </div>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </div>
      </Drawer.Body>
      <Drawer.Footer>
        <Whisper placement="top" trigger="hover" speaker={tooltipAdd}>
          <Button
            onClick={handleAdd}
            appearance="primary"
            size="lg"
            disabled={!stock}
          >
            <Icon icon="shopping-cart" /> Adicionar
          </Button>
        </Whisper>
        <Whisper placement="top" trigger="hover" speaker={tooltipSair}>
          <Button size="lg" onClick={handleClose} appearance="subtle">
            Sair
          </Button>
        </Whisper>
      </Drawer.Footer>
    </Drawer>
  );
};

export default DrawerView;
