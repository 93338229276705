import React, {useEffect, useCallback, useState} from "react";
import {useHistory, useParams} from 'react-router-dom';
import { IconButton, Icon, Loader, Notification } from 'rsuite';
import PDFRomaneio from '../components/PDFRomaneio';
import useQuery from "../hooks/Query";
import { useDispatch, useSelector } from "../hooks/store";
import actions from "../store/actions";
import { State } from "../types";
import api from "../services/api.service";

type Param = {
  id: string
}

const RomaneioPrint = () => {
  const [pdf, setPDF] = useState(null);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const {goBack}= useHistory();
  const { id }= useParams<Param>();

  useEffect(() => {
    generatePDF();
  },[id]);

  const generatePDF = async () => {   
      try {
        setLoadingPDF(true);

        const { data } = await api.post(`/pentaho/reports`,
          {
            file: '01rprint.prpt',
            RO: id,
            IMAGE_PATH: '/var/www/images/'
          },
          {
            responseType: "blob",
            headers: {
              Accept: "application/pdf",
            },
          }
        );

        const blob = new Blob([data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        setPDF(url);
        setLoadingPDF(false)
      } catch (e) {
        setLoadingPDF(false)
        Notification.warning({
          title: "Falha na visualização",
          description: "Ocorreu um erro ao abrir o romaneio.",
        });
      }
    }

  return (
    <div id="panel-printer">
      <div className="panel-header hidden-print inline space-between">
          <h2>Romaneio detalhado</h2>
          <div>
            <IconButton 
              style={{cursor: "pointer", marginLeft: 20}}
              onClick={goBack} 
              abIndex={0} 
              appearance="default" 
              icon={<Icon icon="back-arrow" />} placement="left"
            >
              Voltar
            </IconButton>
          <IconButton 
              style={{cursor: "pointer", marginLeft: 20}}
              onClick={() => {
               const printer = window.open(pdf)
               printer.print()
              }} 
              abIndex={0} 
              appearance="default" 
              color="violet"
              icon={<Icon icon="print" />} placement="right"
            >
              Imprimir
          </IconButton>
          </div>
      </div>
      <div className="body-printer">
        {loadingPDF ? (<Loader size="lg" />): (<PDFRomaneio pdf={pdf}/>)}
      </div>
    </div>
  );
};

export default RomaneioPrint;
