import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider } from './hooks/store';
import { AuthProvider } from './hooks/auth';
import { PermitionProvider } from './hooks/permitions';
import Router from './routes';

import './styles/global.css';
import 'rsuite/dist/styles/rsuite-default.css';
// import 'rsuite/dist/styles/rsuite-dark.css';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <StoreProvider>
          <PermitionProvider>
            <Router />
          </PermitionProvider>
        </StoreProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
