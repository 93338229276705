import { Dispatch } from 'redux';
import permitionsServiceProvider from '../../services/permitions.services';

import * as C from '../../constants';
import { Permition } from '../../types';

export const loadPermitions = (active = true) => ({
  type: C.PERMITIONS_LOAD,
  payload: active,
});

export const receivePermitions = (permitions: Permition[]) => ({
  type: C.PERMITIONS_FETCH,
  payload: permitions,
});

export const createPermitions = (permition: Permition) => ({
  type: C.PERMITIONS_CREATE,
  payload: permition,
});

export const updatePermitions = (permition: Permition) => ({
  type: C.PERMITIONS_UPDATE,
  payload: permition,
});

export const deletePermition = (permition: Permition) => ({
  type: C.PERMITIONS_DELETE,
  payload: permition,
});

export const handleReceivePermitions = () => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadPermitions());
    const permitions = await permitionsServiceProvider.all();

    dispatch(receivePermitions(permitions));
  } catch (error) {
    dispatch(loadPermitions(false));
  }
};

export const handleCreatePermition = (permition: Permition) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadPermitions());
    const data = await permitionsServiceProvider.create(permition);

    dispatch(createPermitions(data));
  } catch (error) {
    dispatch(loadPermitions(false));
  }
};

export const handleUpdatePermition = (permition: Permition) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadPermitions());
    const data = await permitionsServiceProvider.update(permition);

    dispatch(updatePermitions(data));
  } catch (error) {
    dispatch(loadPermitions(false));
  }
};

export const handleDeletePermitions = (permition: Permition) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(loadPermitions());
    await permitionsServiceProvider.remove(permition);

    dispatch(deletePermition(permition));
  } catch (error) {
    dispatch(loadPermitions(false));
  }
};

export default {
  loadPermitions,
  receivePermitions,
  handleReceivePermitions,
  createPermitions,
  handleCreatePermition,
  handleUpdatePermition,
  deletePermition,
  handleDeletePermitions
};
