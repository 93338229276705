import React, { useRef, useState, useCallback } from 'react';
import {
  Drawer,
  Button,
  Schema,
  Divider,
  Form,
  FormProps,
} from 'rsuite';
import { InterestTax } from '../../../types';
import TextField from '../../Form/TextField';

const { NumberType } = Schema.Types;

const SchemaForm = Schema.Model<InterestTax>({
  quantity: NumberType().isRequired('Campo obrigatório'),
  tax: NumberType().isRequired('Campo obrigatório')
});

const DrawerNew: React.FC<{
  show: boolean;
  handleClose: () => void;
  handleSalve: (tax: InterestTax) => void;
}> = ({ show, handleClose, handleSalve }) => {
  const formRef = useRef<FormProps>(null);
  const [formData, setFormData] = useState<InterestTax>(null);

  const handleChange = useCallback((data: InterestTax) => {
    setFormData(data);
  }, []);

  const handleSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();

      if (formRef.current.check()) {
        setFormData(null);
        handleSalve(formData);
      }
    },
    [formData, handleSalve],
  );

  return (
    <Drawer size="lg" show={show} onHide={handleClose}>
      <Drawer.Header>
        <Drawer.Title>Fator de calculo de parcelas</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <h3>Novo Fator</h3>
        <Divider />
        <Form
          fluid
          onChange={handleChange}
          model={SchemaForm}
          ref={formRef}
          formValue={formData}
        >
          <TextField name="quantity" label="Parcela" required />
          <TextField name="tax" label="Fator" required/>
        </Form>
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={handleSubmit} appearance="primary">
          Salvar
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
};

export default DrawerNew;
