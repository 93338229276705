import React, { useEffect, useState, FormEvent, useCallback } from "react";
import { Table, Panel, Button, Icon, Modal } from "rsuite";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "../hooks/store";
import { usePermition } from "../hooks/permitions";
import Actions from "../store/actions";

import ActionCell from "../components/Table/ActionCell";
import CellBool from "../components/Table/CellBool";
import { State, ReportState } from "../types";
import DrawerEdit from "../components/report/DrawerEdit";
import DrawerNew from "../components/report/DrawerNew";
import DrawerView from "../components/report/DrawerView";

const { Cell, Column, HeaderCell } = Table;

const Report: React.FC = () => {
  const [busca, setBusca] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showView, setShowView] = useState(false);
  const [report, setReport] = useState<ReportState>(null);
  const [popup, setPopup] = useState(false);
  const dispatch = useDispatch();
  const { loading, list } = useSelector((state: State) => state?.reports);
  const [value] = useDebounce(busca, 1000);
  const { Can } = usePermition();

  const togglePopUp = async () => {
    setPopup((old) => !old);
  };

  const handleSearchChange = ({
    currentTarget,
  }: FormEvent<HTMLInputElement>) => {
    setBusca(currentTarget.value);
  };

  const toggleNew = useCallback(() => {
    setShowNew((old) => !old);
  },[]);

  const toggleEdit = useCallback(() => {
    setShowEdit((old) => !old);
  },[]);

  const toggleView = useCallback(() => {
    setShowView((old) => !old);
  },[]);

  const openShowEdit = useCallback(
    (report: ReportState) => {
      setReport(report);
      toggleEdit();
    },
    [toggleEdit],
  );

  const openShowView = useCallback(
    (report: ReportState) => {
      setReport(report);
      toggleView();
    },
    [toggleView]
  );

  const openDelete = useCallback((report: ReportState) => {
    setReport(report);
    togglePopUp();
  }, []);

  const handleSaveReport = useCallback(async (data: ReportState) => {
    dispatch(Actions.handleCreateReport(data));
    toggleNew();
  },[dispatch, toggleNew]);

  const handleUpdateReport = useCallback(async (data: ReportState) => {
    dispatch(Actions.handleUpdateReport(data));
    toggleEdit();
  },[dispatch, toggleEdit]);

  const handleDelete = useCallback(() => {
    togglePopUp();
    dispatch(Actions.handleDeleteReport(report));
  }, [dispatch, report]);

  useEffect(() => {
    dispatch(Actions.handleSearchReports(value));
  }, [dispatch, value]);

  const renderHeaderTable = () => (
    <div className="search-table">
      <h2>Relatórios</h2>
      <div
        className="rs-input-group rs-input-group-lg rs-input-group-inside"
        style={{ marginTop: 15 }}
      >
        {Can(["CREATE_REPORT", "ADMIN_FULL_ACCESS"]) && (
          <Button
            appearance="primary"
            size="lg"
            style={{ marginRight: 15 }}
            onClick={toggleNew}
          >
            Novo <Icon icon="save" />
          </Button>
        )}

        <input
          placeholder="Bucar relatório"
          type="text"
          className="rs-input"
          onChange={handleSearchChange}
          value={busca}
        />
      </div>
    </div>
  );

  return (
    <Panel header={renderHeaderTable()}>
      <Table height={480} data={list} loading={loading}>
        <Column width={250}>
          <HeaderCell>Título</HeaderCell>
          <Cell dataKey="title" />
        </Column>

        <Column width={350}>
          <HeaderCell>Descrição</HeaderCell>
          <Cell dataKey="description" />
        </Column>
        <Column width={80}>
          <HeaderCell>Situação</HeaderCell>
          <CellBool
            dataKey="active"
            validateMessage="Ativo"
            invalidateMessage="Desativado"
          />
        </Column>

        <Column width={150}>
          <HeaderCell>Ações</HeaderCell>
          <ActionCell
            dataKey={"id"}
            openEdit={openShowEdit}
            handleDelete={openDelete}
            openView={openShowView}
          />
        </Column>
      </Table>
      <DrawerNew
        show={showNew}
        handleClose={toggleNew}
        handleSalve={handleSaveReport}
      />
      <DrawerView report={report} show={showView} handleClose={toggleView} />
      <DrawerEdit
        report={report}
        show={showEdit}
        handleClose={toggleEdit}
        handleUpdate={handleUpdateReport}
      />
      <Modal backdrop="static" show={popup} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Tem certeza de que quer excluir este relatório?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleDelete()} appearance="primary">
            Sim
          </Button>
          <Button onClick={() => togglePopUp()} appearance="subtle">
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </Panel>
  );
};

export default Report;
