import React  from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import AdminContainer from '../components/AdminContainer';
import PageRomaneio from '../pages/Romaneio';
import PageRomaneioProducts from '../pages/RomaneioProducts';
import NoMatch from '../pages/NoMatch';
import RomaneioPrint from '../pages/RomaneioPrint' 

const RoteRomaneio: React.FC = () => {
  const { path } = useRouteMatch();

  return (
      <Switch>
        <Route path={path} exact>
          <AdminContainer children={<PageRomaneio />} />
        </Route>
        <Route path={`${path}/products`}>
          <AdminContainer children={<PageRomaneioProducts />} />
        </Route>
        <Route path={`${path}/:id/print`}>
          <RomaneioPrint />
        </Route>
        <Route path={`${path}/*`}>
          <NoMatch />
        </Route>
      </Switch>
  );
};

export default RoteRomaneio;