import React, {useEffect} from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { usePermition } from '../hooks/permitions';

import AdminContainer from '../components/AdminContainer';
import Report from './Report';
import Tax from './Tax';
import HomeAdmin from './HomeAdmin';
import Product from './Product';
import UserConfig from './UserConfig';
import Role from './Role';
import Permition from './Permition';
import RolePermitions from './RolePermitions';
import PageRomaneio from './Romaneio';
import PageRomaneioProducts from './RomaneioProducts';
import NoMatch from './NoMatch';
import RomaneioPrint from './RomaneioPrint' 

import RoteRomaneio from '../routes/Romaneio'
import ACL from '../routes/ACL';

const Admin: React.FC = () => {
  const { path } = useRouteMatch();
  const { Can, HasRole} = usePermition();

  console.log(path)

  return (
      <Switch>
        <Route path={path} exact>
          <AdminContainer children={<HomeAdmin />} />
        </Route>
        <Route path={`${path}/reports`}>
          <AdminContainer children={<Report />} />
        </Route>
        <Route path={`${path}/tax`}>
          <AdminContainer children={<Tax />} />
        </Route>
        <Route path={`${path}/products`}>
          <AdminContainer children={<Product />} />
        </Route>
        <Route path={`${path}/userconfig`}>
          <AdminContainer children={<UserConfig />} />
        </Route>
       
        {Can(["ADMIN_FULL_ACCESS", "FS_ACCESS"]) && (
          <Route path={`${path}/romaneios`}>
            <RoteRomaneio />
          </Route>
        )}

        {Can(["ADMIN_FULL_ACCESS"]) && (
          <Route path={`${path}/acl`}>
            <ACL />
          </Route>
        )}
        <Route path={`${path}/*`}>
          <NoMatch />
        </Route>
      </Switch>
  );
};

export default Admin;
