import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Drawer,
  Button,
  Divider,
  ControlLabel,
  FormGroup,
  Tooltip,
  Whisper,
  Icon,
  InputNumber,
  InputGroup,
  FlexboxGrid,
  List,
  Modal,
  IconButton,
  FormProps,
  Schema,
  Form,
  Table,
  TableCellProps,
  SelectPicker,
  FormControl,
  HelpBlock
} from "rsuite";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "../../../hooks/store";
import QRCode from 'qrcode.react';
import { State } from "../../../types";
import Actions from "../../../store/actions";
import { formasPagamento } from "../../../utils";

const { Cell, Column, HeaderCell } = Table;

const PriceCell = ({ rowData, dataKey, ...props }:TableCellProps) => (
  <Cell {...props}>
    {`R$ ${parseFloat(rowData[dataKey]).toFixed(2)}`}
  </Cell>
);

const LabelCell = ({ rowData, dataKey, ...props }:TableCellProps) => {

  if(rowData["parcela"] !== 0) {
    return (
      <Cell {...props}>
        {`R$ ${rowData[dataKey]}`}
      </Cell>
    )
  }

  return (
    <Cell {...props}> - </Cell>
  )
};

const IconCell = ({ rowData, dataKey, ...props }:TableCellProps) => {

  if(rowData[dataKey]) {
    return (
      <Cell {...props} >
        <Icon icon="circle" style={{ color: '#37ab3c' }} />
      </Cell>
    )
  }

  return (
    <Cell {...props}>
      <Icon icon="circle-o" />
    </Cell>
  )
};

type Props = {
  show: boolean;
  handleClose: () => void;
  isNewSimulate: boolean,
}

const styleFlexEnd = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  height: '50px'
};

const styleCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

const { StringType} = Schema.Types;

type Send = {
  cliente_nome: string,
  cliente_telefone: string,
  vendedor: string,
}

const SchemaForm = Schema.Model<Send>({
  cliente_nome: StringType().isRequired('O nome cliente é obrigatório'),
  cliente_telefone: StringType().isRequired('Informe o telefone do cliente'),
  vendedor: StringType().isRequired('O nome do vendedor é obrigatório'),
});

const DrawerView = ({ show, handleClose, isNewSimulate }:Props) => {
  const { 
    list, 
    input_value, 
    discount, 
    total_value, 
    total_discount_value, 
    total_final,
    payment_form, 
  } = useSelector((state: State) => state?.bag);
  const formRef = useRef<FormProps>(null);
  const { list: taxs } = useSelector((state: State) => state?.interesttax);
  const dispatch = useDispatch();
  const [parcelas, setParcelas] = useState([]);
  const [parcelasCard, setParcelasCard] = useState([]);
  const [parcelamento, setParcelamento] = useState(null);
  const [formaPagamento, setFormaPagamento] = useState(1);
  const [showSendWhats, setShowSendWhats] = useState(false);
  const [formData, setFormData] = useState<Send>(null);
  const [textWhatsapp, setTextWhatsapp] = useState('');
  const [viewQRCode, setViewQRCode] = useState(false);
  const [labelFormaPagamento, setLabelFormaPagamento] = useState('À vista');
  const [ivalue, setIvalue] = useState(0);
  const [debounceInputValue] = useDebounce(ivalue, 1000);

  const tooltipSend = <Tooltip>Enviar orçamento por Whatsapp</Tooltip>;
  const tooltipSair = <Tooltip>Sair (Esc)</Tooltip>;

  const handleSelectParcelamento = useCallback((data) => {
    if(formaPagamento !== 3){
      const newParcelas = parcelas.map(i => {
        if(i?.parcela === data?.parcela){
          return ({ ...i, check: true})
        }
  
        return ({ ...i, check: false});
      });

      setParcelas(newParcelas);
      setParcelamento({...data, check: true});
    }else{
      const newParcelas = parcelasCard.map(i => {
        if(i?.parcela === data?.parcela){
          return ({ ...i, check: true})
        }
  
        return ({ ...i, check: false});;
      });

      setParcelasCard(newParcelas);
      setParcelamento({...data, check: true});
    }

    dispatch(Actions.addBagPaymentForm({
      ...payment_form,
      plano: { 
        parcela: data.parcela, 
        parcela_label: data.parcelaLabel, 
        valor_parcela: parseFloat(data.parcelaValor), 
        total: parseFloat(data.total)
      },
    }));
    
  }, [formaPagamento, parcelas, parcelasCard]);

  const handleChange = useCallback((data: Send) => {
    setFormData(data);
  }, []);

  const handleSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();

      if (formRef.current.check()) {
        // mostrar QR Code com o link
        const now = new Date();
        const prodts = list.map(i => {
          const msg = `*${i.item.codigo_produto.trim()}* - ${i.item.descricao.toUpperCase().trim()} *(${i.qtd})*`;
          return encodeURIComponent(msg);
        });
        let parcLabel = '';
        
        const formaPagamentoLabel = formasPagamento[formaPagamento - 1]?.label;

        if(formaPagamento === 1) {
          parcLabel = ` R$ ${parcelamento?.total}`;
        }

        
        if(formaPagamento !== 1) {
          // parcLabel = ` ${parcelamento?.parcelaLabel} de R$ ${parcelamento?.parcelaValor} *Total:* R$ ${parcelamento?.total}`;
          parcLabel = ` ${parcelamento?.parcelaLabel} de R$ ${parcelamento?.parcelaValor}`;
        }

        const parcelamentoLabel = `*Pagamento (${formaPagamentoLabel}):* ${parcLabel}`;
        
        const texts = [
          encodeURI('*FEIRÃO DOS MÓVEIS MAGAZINE*'),
          encodeURI(`Data: ${now.toLocaleDateString().concat(" ", now.toLocaleTimeString())}`),
          encodeURI(' '),
          encodeURI(`Olá *${formData.cliente_nome}*,`),
          encodeURI(`sou o vendedor (a) _*${formData.vendedor}*_`),
          encodeURI(`Segue abaixo a *Simulação do Pedido*`),
          encodeURI(`--------------------------------`),
          ...prodts,
          encodeURI(`-------------------------------`),
          // encodeURI(`Total dos produtos: *R$ ${total_value}*`),
          // encodeURI(`Desconto: *R$ ${total_discount_value}* (${discount}%)`),
          encodeURI(`Valor com desconto: *R$ ${total_final}* (${discount}%)`),
        ];

        if(!!input_value){
          texts.push(encodeURI(`Entrada: *R$ ${(+input_value).toFixed(2)}*`));
        }

        texts.push(...[
          encodeURI(`Saldo: *R$ ${(total_final - input_value).toFixed(2)}*`),
          encodeURI(parcelamentoLabel),
          encodeURI(' '),
          encodeURI(`*Importante!* Esta simulação tem validade de até 24h após o envio ou até durar o estoque dos produtos.`),
        ])
        
        const text = texts.join('%0a');

        const url = `https://wa.me/55${formData.cliente_telefone}?text=${text}`;

        setTextWhatsapp(url);
        setViewQRCode(true);
      }
    },
    [formData, parcelamento, formaPagamento, formasPagamento, input_value, total_value, total_discount_value, discount, total_final]
  );

  const toggleModal = () => {
    setShowSendWhats(old => !old);
    setTextWhatsapp('');
    setViewQRCode(false);
  }

  const resetFormData = () => {
    setFormData(null);
  }

  const handleChandeInputValue = useCallback((value) => {
    setIvalue(value);
    // dispatch(Actions.addBagInputValue(value));
  }, [list, ivalue]);

  const handleChandeFormaPagamento = useCallback((value) => {
    if(value === 1){ // se for avista, remover entrada.
      dispatch(Actions.addBagInputValue(0));
    }

    setFormaPagamento(value);
    setParcelamento(null);
  }, [list, formaPagamento, input_value]);

  useEffect(() => {
    dispatch(Actions.handleFetchInterestTax());
  }, []);

  useEffect(() => {
    if(isNewSimulate){
      resetFormData();
      setTextWhatsapp('');
      setViewQRCode(false);
      setParcelamento(null);
      setFormaPagamento(1);
      setParcelas([]);
      setParcelasCard([]);
      setLabelFormaPagamento('À vista');
    }
  }, [isNewSimulate]);

  useEffect(() => {
    dispatch(Actions.addBagInputValue(debounceInputValue));
  }, [debounceInputValue])

  useEffect(() => {
    let type = 1;
    let label = ``;
    let parcel = {
      check: false,
      parcela: 0,
      parcelaLabel: ``, 
      parcelaValor: ``,
      total: ``,
    };

    if(taxs) {
      if(formaPagamento === 1){ // Á vista
        type = 1;
        label = `À vista`;
        const parcelaValor = (total_final - input_value);
        parcel =  {
          check: true,
          parcela: 0,
          parcelaLabel: `À vista`, 
          parcelaValor: parcelaValor.toFixed(2),
          total: parcelaValor.toFixed(2),
        };

        const parcs = [
         parcel,
        ];
  
        setParcelas(parcs);
        setParcelamento({...parcel});
        dispatch(Actions.addBagInputValue(0));
        setLabelFormaPagamento('À vista');
      }

      if(formaPagamento === 2){ // Crediário
        type = 2;
        label = 'Crediário';
        let fTaxs = taxs.filter(t => t?.quantity !== 0);

        const parcs = fTaxs.map(t => {
          const parcela = t?.quantity;

          const parcelaLabel = `${parcela}x`;
          const parcelaValor = (total_final - input_value) * t?.tax;
          const par = {
            parcela,
            parcelaLabel, 
            parcelaValor: parcelaValor.toFixed(2),
            total: (parcelaValor * t.quantity).toFixed(2),
          };

          if(parcelamento && parcelamento?.parcela === parcela){
            parcel =  {
              ...par,
              check: true,
            };

            return parcel;
          }else{
            parcel =  {
              ...par,
              check: false,
            };

            return parcel;
          }
        });
  
        setParcelas(parcs);
        setLabelFormaPagamento('Crediário');
      }

      if(formaPagamento === 3){ // Cartão
        type = 3;
        label = 'Cartão de crédito';

        const parcs = Array(10).fill('').map((t, i) => {
          const parcela = i + 1;
          const parcelaLabel = `${parcela}x`;
          const parcelaValor = ((total_final - input_value) / parcela);
          const par = {
            parcela,
            parcelaLabel, 
            parcelaValor: parcelaValor.toFixed(2),
            total: (total_final - input_value).toFixed(2),
          }
  
          if(parcelamento && parcelamento?.parcela === parcela){
            parcel =  {
              ...par,
              check: true,
            };

            return parcel;
          }else{
            parcel =  {
              ...par,
              check: false,
            };

            return parcel;
          }
        });
  
        setParcelasCard(parcs);
        setLabelFormaPagamento('Cartão de crédito');
      }
    }

    // atualizar forma de pagamento com detalhes do parcelamento
    dispatch(Actions.addBagPaymentForm({
      type,
      label,
      plano: type === 1 ? { 
        parcela: parcel.parcela, 
        parcela_label: parcel.parcelaLabel, 
        valor_parcela: parseFloat(parcel.parcelaValor), 
        total: parseFloat(parcel.total)
      } : null,
    }));

  }, [
    taxs, 
    list, 
    input_value, 
    total_final, 
    formaPagamento
  ]);

  return (
    <>
    <Drawer full show={show} onHide={handleClose} className="box-bag">
      <Drawer.Header>
        <Drawer.Title><h1>Simulação de compra</h1></Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Divider />
          <FlexboxGrid>
            <FlexboxGrid.Item
              colspan={24}
              style={{
                ...styleFlexEnd,
                marginBottom: 20,
              }}
            >
              <FormGroup style={{marginRight: 15}}>
                <ControlLabel style={{fontSize: 13, color:"#222"}}>Total dos produtos</ControlLabel>
                <InputGroup>
                    <InputNumber
                      className={'custom-input-number'}
                      min={0}
                      value={total_value}
                    />
                </InputGroup>
              </FormGroup>
              {formaPagamento !== 1 && (
                <FormGroup style={{marginRight: 15}}>
                  <ControlLabel style={{fontSize: 13, color:"#222"}}>Entrada</ControlLabel>
                  <InputGroup>
                      <InputNumber
                        className={'custom-input-number'}
                        max={+total_final}
                        min={0}
                        value={ivalue}
                        onChange={(value, event) => handleChandeInputValue(value)}
                      />
                  </InputGroup>
                </FormGroup>
              )}
              <FormGroup style={{marginRight: 15}}>
                <ControlLabel style={{fontSize: 13, color:"#222"}}>Forma de Pagamento</ControlLabel>
                <SelectPicker 
                  data={formasPagamento} 
                  block
                  value={formaPagamento}
                  onChange={(value, event) => handleChandeFormaPagamento(value)} 
                />
              </FormGroup>
            </FlexboxGrid.Item>
          </FlexboxGrid>
            <FlexboxGrid justify="space-between" className="grid-simulate">
            <FlexboxGrid.Item colspan={12}>
              <Divider>Detalhamento</Divider>
              <List bordered style={{width: "100%"}}>
              <List.Item>
                <p style={{fontSize: "1.2rem"}}><strong>Total dos produtos: </strong>R$ {total_value}</p>
              </List.Item>
              <List.Item>
                <p style={{fontSize: "1.2rem"}}><strong>Desconto: </strong>R$ {total_discount_value} ({discount}%)</p>
              </List.Item>
              <List.Item>
                <p style={{fontSize: "1.2rem"}}><strong>Valor com desconto: </strong>R$ {total_final}</p>
              </List.Item>
              {formaPagamento !== 1 && (
                <List.Item>
                  <p style={{fontSize: "1.2rem"}}><strong>Entrada: </strong>R$ {(+input_value).toFixed(2)}</p>
                </List.Item>
              )}
              <List.Item>
                <p style={{fontSize: "1.2rem"}}><strong>{labelFormaPagamento}: </strong>R$ {(total_final - input_value).toFixed(2)}</p>
              </List.Item>
              </List>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={10} className="grid-table-parcelas">
            <Divider>Simulação de Parcelamento</Divider>
            <Table 
              className="table-parcelas"
              height={450} 
              data={formaPagamento !== 3 ? parcelas : parcelasCard} 
              style={{cursor: "pointer"}}
              onRowClick={(rowData) => handleSelectParcelamento(rowData)}>
              <Column width={50}>
                <HeaderCell>#</HeaderCell>
                <IconCell dataKey="check" />
              </Column>
              <Column width={100}>
                <HeaderCell>Parcela</HeaderCell>
                <Cell dataKey="parcelaLabel" />
              </Column>
              <LabelCell width={100}>
                <HeaderCell>Valor parcela</HeaderCell>
                <Cell dataKey="parcelaValor" />
              </LabelCell>
              <Column width={150}>
                <HeaderCell>Total</HeaderCell>
                <PriceCell dataKey="total" />
              </Column>
            </Table>
            </FlexboxGrid.Item>
          </FlexboxGrid>
      </Drawer.Body>
      <Drawer.Footer>
          <Whisper placement="top" trigger="hover" speaker={tooltipSend}>
            <IconButton onClick={toggleModal} abIndex={0} color="green" icon={<Icon icon="whatsapp" size="2x" />} placement="right" disabled={parcelamento === null}>
              Enviar Orçamento
            </IconButton>
          </Whisper>
          <Whisper placement="top" trigger="hover" speaker={tooltipSair}>
            <Button size="lg" onClick={handleClose} appearance="subtle">
              Sair
            </Button>
          </Whisper>
      </Drawer.Footer>  
    </Drawer>
    <Modal size="lg" show={showSendWhats && show} onHide={toggleModal}>
      <Modal.Header>
        <Modal.Title>Enviar orçamento (Whatsapp)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          onChange={handleChange}
          model={SchemaForm}
          ref={formRef}
          formValue={formData}
        >
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={12}>
              <FormGroup>
                <ControlLabel>Nome do Cliente</ControlLabel>
                <FormControl name="cliente_nome" />
                <HelpBlock>Obrigatório</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Telefone do Cliente Obs. Somente números</ControlLabel>
                <FormControl name="cliente_telefone" />
                <HelpBlock>Obrigatório</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Nome do vendedor</ControlLabel>
                <FormControl name="vendedor" />
                <HelpBlock>Obrigatório</HelpBlock>
              </FormGroup>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={12} style={{...styleCenter}}>
              {(viewQRCode && textWhatsapp) && (<QRCode size={300} includeMargin={true} value={textWhatsapp}/>)}
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Divider />
        <Button onClick={handleSubmit} color="green">
          Gerar link de compartilhamento
        </Button>
        <Button onClick={toggleModal} appearance="subtle">
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default DrawerView;
