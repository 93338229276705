import React from 'react';

import ButtonSignOut from '../ButtonSignOut';
import ButtonConfig from '../ButtonConfig';

import ProfileImage from '../../assets/profile_missing.svg';

interface Props {
  username: string;
  imageUrl?: string;
}

const UserPanel: React.FC<Props> = ({ username, imageUrl = ProfileImage }) => {
  return (
    <li className="user-panel">
      <div className="image">
        <img src={imageUrl} className="img-circle" alt={username} />
      </div>
      <div className="info">
        <p>{username}</p>
        <div className="info-controlers">
          <ButtonSignOut />
          <ButtonConfig />
        </div>
      </div>
    </li>
  );
};

export default UserPanel;
