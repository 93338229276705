import {
  INTEREST_TAX_LOAD,
  INTEREST_TAX_FETCH,
  INTEREST_TAX_CREATE,
  INTEREST_TAX_UPDATE,
  INTEREST_TAX_DELETE,
  INTEREST_TAX_LIST,
} from '../../constants';

import { InterestTax, ActionTypes } from '../../types';

type State = {
  loading: boolean;
  list: InterestTax[];
};

const defaultState: State = {
  loading: true,
  list: [],
};

const reducerInterestTax = (state = defaultState, action: ActionTypes) => {
  return (
    {
      [INTEREST_TAX_LOAD]: {
        ...state,
        loading: action.payload,
      },
      [INTEREST_TAX_FETCH]: {
        ...state,
        loading: false,
        list: action.payload,
      },
      [INTEREST_TAX_LIST]: [...state.list],
      [INTEREST_TAX_CREATE]: {
        ...state,
        loading: false,
        list: [...state.list, action.payload],
      },
      [INTEREST_TAX_UPDATE]: {
        ...state,
        loading: false,
        list: [
          ...state.list.filter(
            (interestTax: InterestTax) => interestTax?.id !== action.payload?.id,
          ),
          action.payload,
        ],
      },
      [INTEREST_TAX_DELETE]: {
        ...state,
        loading: false,
        list: [
          ...state.list.filter(
            (interestTax: InterestTax) => interestTax?.id !== action.payload,
          ),
        ],
      },
    }[action.type] || state
  );
};

export default reducerInterestTax;
