import React from 'react';
import { Nav, NavItemProps } from 'rsuite';
import { Link, LinkProps, useRouteMatch } from 'react-router-dom';

const MyLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { href, ...rest } = props;

  return <Link to={href} ref={ref} {...rest} />;
});

interface CustomNavLinkProps extends NavItemProps {
  exact?: boolean;
}

const CustomNavLink: React.FC<CustomNavLinkProps> = (props) => {
  const { href } = props;
  const { exact, ...rest } = props;
  const match = useRouteMatch({
    path: href,
    exact,
  });

  return <Nav.Item componentClass={MyLink} {...rest} active={!!match} />;
};

export default CustomNavLink;
