import React from 'react';

import image404 from '../assets/404.svg';

const NoMatch: React.FC = () => (
  <div className="error-page">
    <div className="item">
      <img src={image404} alt="Image 404" />
      <div className="text">
        <h1 className="code">404</h1>
        <p>Página não encontrata</p>
      </div>
    </div>
  </div>
);

export default NoMatch;
