import React, { useEffect } from "react";
import { 
  Panel,
  List,
  FlexboxGrid,
  Tooltip,
  Loader,
  Whisper,
  IconButton,
  Icon,
} from "rsuite";
import {useHistory} from "react-router-dom"
import { useDispatch, useSelector } from "../hooks/store";
import { usePermition } from "../hooks/permitions";
import Actions from "../store/actions";

import { State, Role as TRole } from "../types";

const styleItem = {
  padding: 10,
  fontSize: "1rem",
  fontWeight: 600
}

const styleLoad = {
  padding: 10,
  margin: 15
}

const tooltipPermissions = <Tooltip>Ver todas as permissões de usuários</Tooltip>;

const Role = () => {
  const roles = useSelector((state: State) => state?.roles);
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    dispatch(Actions.handleReceiveRoles());
  },[]);

  const handleNavegationToRole = (role: TRole) => {
    history.push(`/admin/acl/roles/${role.id}`, role);
  }

  const handleNavegationToPermissions = () => {
    history.push(`/admin/acl/permitions`);
  }
 
  const renderHeader = () => (
    <div className="search-table">
      <div className="search-tools">
        <h2>Configurações de Cargos</h2>
        <div>
          <Whisper placement="top" trigger="hover" speaker={tooltipPermissions}>
            <IconButton 
              onClick={handleNavegationToPermissions} 
              abIndex={0} 
              appearance="primary" 
              icon={<Icon icon="ban" />} 
              placement="right"
            >
              Ver permissões
            </IconButton>
          </Whisper>
        </div>
      </div>
    </div>
  );

  return (
    <Panel header={renderHeader()}>
      {roles?.loading && (
      <div style={styleLoad}>
        <Loader size="md" content="Carregando cargos..." />
      </div>
    )}

      <List hover>
        {roles?.list.map((role, index) => (
          <List.Item 
            key={`${role.id}-${role.name}`}  
            index={index} 
            onClick={() => handleNavegationToRole(role)}>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={1} style={styleItem}>
                <Icon icon="character-area"  size="2x"/>
              </FlexboxGrid.Item>            
              <FlexboxGrid.Item colspan={23} style={styleItem}>
                {role.name}
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </List.Item>
        ))}
      </List>
    </Panel>
  );
};

export default Role;
