import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  Drawer,
  Button,
  Schema,
  Toggle,
  Divider,
  TagPicker,
  Form,
  ControlLabel,
  HelpBlock,
  FormGroup,
  FormProps,
  FormControl,
} from 'rsuite';
import { ReportState, RoleTagPicker } from '../../../types';
import TextField from '../../Form/TextField';
import { dataFilter, roleTransformeTagPicker } from '../../../utils';
import roleServiceProvider from '../../../services/roles.services';

const { StringType, BooleanType, ArrayType } = Schema.Types;

const SchemaForm = Schema.Model<ReportState>({
  active: BooleanType(),
  title: StringType().isRequired('Campo obrigatório'),
  description: StringType(),
  file_prpt: StringType().isRequired('Campo obrigatório'),
  fields: ArrayType<string>(),
  roles: ArrayType<string>(),
});

const DrawerNew: React.FC<{
  show: boolean;
  handleClose: () => void;
  handleSalve: (r: ReportState) => void;
}> = ({ show, handleClose, handleSalve }) => {
  const formRef = useRef<FormProps>(null);
  const [formData, setFormData] = useState<ReportState>(null);
  const [roles, setRoles] = useState<RoleTagPicker[]>();

  const handleChange = useCallback((data: ReportState) => {
    setFormData(data);
  }, []);

  const handleChangeActive = useCallback((active)=>{
    setFormData(old => ({...old, active}));
  },[]);

  const handleSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();

      if (formRef.current.check()) {
        setFormData(null);
        handleSalve(formData);
      }
    },
    [formData, handleSalve],
  );

  useEffect(() => {
    const getRoles = async () => {
      const data = await roleServiceProvider.all();
      const roles = await roleTransformeTagPicker(data);
      
      setRoles(roles);
    };

    getRoles();
  }, [])

  return (
    <Drawer size="lg" show={show} onHide={handleClose}>
      <Drawer.Header>
        <Drawer.Title>Editando relatório</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <h3>Novo Relatório</h3>
        <Divider />
        <Form
          fluid
          onChange={handleChange}
          model={SchemaForm}
          ref={formRef}
          formValue={formData}
        >
          <FormGroup>
            <ControlLabel>Ativo</ControlLabel>
            <Toggle
              size="lg"
              checkedChildren="Sim"
              unCheckedChildren="Não"
              name="active"
              onChange={handleChangeActive}
              checked={formData?.active}
            />
          </FormGroup>
          <TextField name="title" label="Título" required />
          <TextField name="description" label="Descrição" />
          <TextField name="file_prpt" label="Arquivo (.prpt)" required />

          <FormGroup>
            <ControlLabel>Filtros</ControlLabel>
            <FormControl
              name="fields"
              accepter={TagPicker}
              data={dataFilter}
              style={{ width: '100%' }}
              maxHeight={150}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Permissões</ControlLabel>
            <FormControl
              name="roles"
              accepter={TagPicker}
              data={roles}
              style={{ width: '100%' }}
              maxHeight={150}
            />
          </FormGroup>

        </Form>
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={handleSubmit} appearance="primary">
          Salvar
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
};

export default DrawerNew;
