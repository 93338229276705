import * as C from '../../constants';

export const toggleNavbar = () => ({
  type: C.NAVBAR_TOGGLE
});

export const getNavbar = () => ({
  type: C.NAVBAR
});

export const closeNavbar = () => ({
  type: C.NAVBAR_CLOSE
});

export const setNavbar = (expand = true) => ({
  type: C.NAVBAR_SET,
  payload: {
    expand
  }
});


export default {
  toggleNavbar,
  getNavbar,
  closeNavbar,
  setNavbar
};
