import {
  PRODUCTS_LOAD,
  PRODUCTS_FETCH,
  PRODUCTS_FETCH_PAGINATION
} from '../../constants';

import { Product, ActionTypes } from '../../types';

type State = {
  loading: boolean;
  list: Product[];
};

const defaultState: State = {
  loading: false,
  list: [],
};

const reducerProducts = (state = defaultState, action: ActionTypes) => {
  return (
    {
      [PRODUCTS_LOAD]: {
        ...state,
        loading: action.payload,
      },
      [PRODUCTS_FETCH]: {
        ...state,
        loading: false,
        list: action.payload,
      },
      [PRODUCTS_FETCH_PAGINATION]: {
        ...state,
        loading: false,
        list: [...state.list].concat(action.payload),
      },
    }[action.type] || state
  );
};

export default reducerProducts;
