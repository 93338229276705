import React from 'react';
import { Switch, Route } from 'react-router-dom';

import GuestRoute from './GuestRoute';
import PrivateRoute from './PrivateRoute';

import { Home, Login, Admin, NoMatch } from '../pages';

const Router: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <GuestRoute path="/login">
        <Login />
      </GuestRoute>
      <PrivateRoute path="/admin">
        <Admin />
      </PrivateRoute>
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
};

export default Router;
