import React, { useEffect, useState, FormEvent, useCallback, useRef } from "react";
import {Panel, FormProps, Schema, Form, FormGroup, Button, ControlLabel, FormControl, HelpBlock, List } from "rsuite";
import { useDispatch, useSelector } from "../hooks/store";
import Actions from "../store/actions";
import { useAuth } from '../hooks/auth';

import InputPickerStore from "../components/Form/InputPickerStore";

import { State, UserConfig } from "../types";

const { NumberType } = Schema.Types;

const SchemaForm = Schema.Model<UserConfig>({
  store: NumberType(),
});

type Config = {
  store: number;
}

const UseConfig = () => {
  const formRef = useRef<FormProps>(null);
  const [formData, setFormData] = useState<Config>(null);
  const { loading, config } = useSelector((state: State) => state?.userconfig);
  const dispatch = useDispatch();
  const { user, setConfig } = useAuth();


  const handleChange = useCallback((data: Config) => {
    setFormData(data);
  }, []);

  const handleSubmit = useCallback(() => {
    if (formRef.current.check()) {
      dispatch(Actions.handleUpdateUserConfig(formData));
      setConfig(formData);
      console.log(formData)
    }
  }, [formData]);

  const renderHeader = () => (
    <div>
      <h2>Configurações de usuário</h2>
    </div>
  );

  useEffect(() => {
    dispatch(Actions.receiveUserConfig());
  }, []);

  return (
    <Panel header={renderHeader()}>
      <List bordered style={{marginBottom: 25}}>
        <List.Item>
          <p style={{fontSize: "1.2rem"}}><strong>Usuário: </strong>{user.name}</p>
        </List.Item>
        <List.Item>
          <p style={{fontSize: "1.2rem"}}><strong>Grupo: </strong>{user.grupo}</p>
        </List.Item>
      </List>
      <Form
          fluid
          onChange={handleChange}
          model={SchemaForm}
          ref={formRef}
          formValue={formData}
          formDefaultValue={{store: user?.config?.store}}
          autocomplete="off"
        >
          <FormGroup>
            <InputPickerStore all name="store"/>
            <HelpBlock style={{marginTop: '-25px'}}>Sua Loja atual</HelpBlock>
          </FormGroup>
        </Form>
        <Button
          size="lg"
          appearance="primary"
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
          style={{marginTop: 30}}
        >
          {loading ? 'aguarde...' : 'Atualizar Ajustes'}
        </Button>
    </Panel>
  );
};

export default UseConfig;
