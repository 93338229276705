import React, { createContext, useContext } from 'react';
import { useAuth } from './auth';
import { PermitionContextData } from '../types';

const PermitionContext = createContext<PermitionContextData>({} as PermitionContextData);

const PermitionProvider: React.FC = ({ children }) => {
  const { user } = useAuth();

  function Can(perms: string[] ) {
    const { permitions } = user;
    const exist = permitions.findIndex(p => perms.includes(p?.name) );

    if(exist !== -1) return true;

    return false;
  }

  function HasRole(ros: string[]) {
    const { roles } = user;

    const exist = roles.findIndex(r => ros.includes(r.role) );

    if(exist !== -1) return true;
    
    return false;
  }

  return (
    <PermitionContext.Provider value={{ Can, HasRole }} >
      {children}
    </PermitionContext.Provider>
  );
};

function usePermition(): PermitionContextData {
  const context = useContext(PermitionContext);

  if (!context) {
    throw new Error('usePermition must be used within an AuthProvider.');
  }

  return context;
}

export { PermitionProvider, usePermition };
