import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

function authHeader() {
  const user = JSON.parse(localStorage.getItem("@feirao:user"));
  const headers = {};

  if (user !== undefined) {
    return {
      ...headers,
      Authorization: `Bearer ${user?.token}`,
    };
  } else {
    return headers;
  }
}

const api = axios.create({
  baseURL: API_URL,
  headers: authHeader(),
});

api.interceptors.response.use(
  (response) => {
    if (response.config.url === "/sessions" && response.status === 200) {
      api.defaults.headers.Authorization = `Bearer ${response.data?.token}`;
    }

    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("@feirao:user");
      api.defaults.headers.Authorization = "";
      window.location.assign("/login");
    }
  }
);

export default api;
