import React from "react";
import {
  Table,
  IconButton,
  Icon,
  TableCellProps,
  Tooltip,
  Whisper,
} from "rsuite";
import { format as formatFns } from 'date-fns';

const { Cell } = Table;

const DateCell: React.FC<TableCellProps> = ({
  rowData,
  dataKey,
  format = 'dd/MM/yyyy H:m',
  ...props
}) => {
  
  return (
    <Cell {...props}>
      {formatFns(new Date(rowData[dataKey]), format)}
    </Cell>
  );
};

export default DateCell;
